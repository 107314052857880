import React from 'react';

export const initialData = {
  header: {
    typeId: 'mastheadLayout',
    topLeftSection: {
      backgroundColour: '#CB333B',
      alignment: 'LEFT',
      components: [
        {
          componentId: 'informationTextComponent',
          contentId: '6LL49b94s5T271KdIMLB9X',
          variantId: 'LIGHT',
          id: '6LL49b94s5T271KdIMLB9X',
          data: {
            variantId: 'LIGHT',
            displayText1:
              'Save the tax. GST/HST break from the Government of Canada on select products is now available.',
            callToAction: {
              text: 'Shop now',
              url: 'https://www.shoppersdrugmart.ca/page/savethetax?lang=en',
            },
          },
        },
      ],
    },
    topRightSection: {
      alignment: 'RIGHT',
      components: [
        {
          componentId: 'navigationItemComponent',
          contentId: 'vEZ4ol5YkOUfseFSS84wV',
          variantId: 'LIGHT',
          id: 'vEZ4ol5YkOUfseFSS84wV',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Sign%20in_padded.svg',
            },
            mobileInitialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/Sign%20in_my%20account_mobileinitialicon.svg',
            },
            mobileFilledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/sign%20in_my%20account_mobilefilledicon.svg',
            },
            label: 'Sign In',
            callToAction: {
              text: 'Sign In',
              url: 'https://www.shoppersdrugmart.ca/login',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
      ],
    },
    logoSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'logoWithCtaComponent',
          contentId: 'TKYMh0r7NuCAwlmTzRiLD',
          id: 'TKYMh0r7NuCAwlmTzRiLD',
          data: {
            largeLogo: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/SDM%20logo%20(1).svg',
              altText: 'Shoppers Drug Mart',
            },
            mediumLogo: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/SDM%20logo%20(1).svg',
              altText: 'Shoppers Drug Mart',
            },
            smallLogo: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/SDM%20logo-small.svg',
            },
            callToAction: {
              text: ' ',
              url: 'https://www.shoppersdrugmart.ca/?lang=en',
            },
          },
        },
      ],
    },
    storeSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'navigationItemComponent',
          contentId: '6Pv9I36SCfRPcOKUyvp62H',
          variantId: 'DARK',
          id: '6Pv9I36SCfRPcOKUyvp62H',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/location_on.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/location_on_Red%20on%20hover.svg',
            },
            mobileInitialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/Store%20service_initial_icon.svg',
            },
            mobileFilledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/Store%20services_mobilefilledicon.svg',
            },
            label: 'Store & Services',
            callToAction: {
              text: 'Store & Services',
              url: 'https://www.shoppersdrugmart.ca/en/store-locator',
            },
            showCount: false,
            dropdownItems: [
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Passport Photos',
                  url: 'https://www.shoppersdrugmart.ca/en/more-by-shoppers/passport',
                },
              },
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Fresh Food',
                  url: 'https://www.shoppersdrugmart.ca/en/more-by-shoppers/fresh-food',
                },
              },
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Presto',
                  url: 'https://www.shoppersdrugmart.ca/en/more-by-shoppers/presto',
                },
              },
              {
                variantId: 'LINK',
                callToAction: {
                  text: 'Find a Store',
                  url: 'https://www.shoppersdrugmart.ca/en/store-locator',
                },
              },
            ],
            dropdownOpenIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/expand_more_white.svg',
            },
            dropdownCloseIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/expand_less_white_icon.svg',
            },
          },
        },
      ],
    },
    searchSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'searchBarComponent',
          contentId: '1qiJ8xCCLGfwnSZPq5h7tX',
          id: '1qiJ8xCCLGfwnSZPq5h7tX',
          data: {
            displayText1: 'Search',
          },
        },
      ],
    },
    featuredServicesSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'navigationItemComponent',
          contentId: '6cfEqEH07eJuywgoMeB4zr',
          variantId: 'DARK',
          id: '6cfEqEH07eJuywgoMeB4zr',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Pill%20icon_padded.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/Pill%20icon_on%20hover.svg',
            },
            label: 'Prescriptions',
            callToAction: {
              text: 'Prescriptions ',
              url: 'https://www.shoppersdrugmart.ca/health/digital-pharmacy?lang=en',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
        {
          componentId: 'navigationItemComponent',
          contentId: '2FIQsFerZDVVt7kKNCG5NY',
          variantId: 'DARK',
          id: '2FIQsFerZDVVt7kKNCG5NY',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/favorite_border_padded.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/my%20shop_on%20hover.svg',
            },
            mobileInitialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/my%20shop_mobileinitialicon.svg',
            },
            mobileFilledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/icons/my%20shop_mobilefilledicon.svg',
            },
            label: 'Favourites',
            callToAction: {
              text: 'Favourites',
              url: 'https://www.shoppersdrugmart.ca/favourite',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
      ],
    },
    cartSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'navigationItemComponent',
          contentId: '47yrTTtV6rc1SDwFvznhz9',
          variantId: 'DARK',
          id: '47yrTTtV6rc1SDwFvznhz9',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Bag_padded.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/hover/shopping_bag_empty_on%20Hover.svg',
            },
            filledIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Bag_items%20in%20bag_padded.svg',
            },
            label: 'Bag',
            callToAction: {
              text: 'Bag',
              url: 'https://www.shoppersdrugmart.ca/shoppingbag?lang=en',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
        {
          componentId: 'navigationItemComponent',
          contentId: 'FjNx0bkO2fFqz1JI9G7FR',
          variantId: 'DARK',
          id: 'FjNx0bkO2fFqz1JI9G7FR',
          data: {
            initialIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Bag_padded.svg',
            },
            updatedIcon: {
              url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/Bag_items%20in%20bag_padded.svg',
            },
            label: 'Checkout',
            callToAction: {
              text: 'Checkout',
              url: 'https://www.shoppersdrugmart.ca/purchase',
            },
            showCount: false,
            dropdownItems: [],
          },
        },
      ],
    },
    categoryNavigationSection: {
      alignment: 'NONE',
      components: [
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: 'sStuTcZLP04O2HTO9hy7F',
          id: 'sStuTcZLP04O2HTO9hy7F',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: 'sStuTcZLP04O2HTO9hy7F',
            id: 'sStuTcZLP04O2HTO9hy7F',
            callToAction: {
              text: 'Health & Pharmacy',
              url: 'https://www.shoppersdrugmart.ca/health/pharmacist-consultations?nav=/health/pharmacist-consultations',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['Health & Pharmacy', 'Prescriptions'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4GIhiqsvQ8HO7XIjNAMKTY',
                id: '4GIhiqsvQ8HO7XIjNAMKTY',
                callToAction: {
                  text: 'Prescriptions',
                  url: 'https://mypharmacy.shoppersdrugmart.ca/?nav=mypharmacy.shoppersdrugmart.ca',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4eJ6k1a2RbsDrNGyvupbOC',
                    id: '4eJ6k1a2RbsDrNGyvupbOC',
                    l3Data: [
                      {
                        hierarchy: ['Health & Pharmacy', 'Prescriptions', 'Manage my Refills '],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3KLmpBuwBA0KokHnrPbvV',
                        id: '3KLmpBuwBA0KokHnrPbvV',
                        l4Data: [
                          {
                            text: 'Set up Auto Refills',
                            url: 'https://mypharmacy.shoppersdrugmart.ca/en/auto-refill',
                          },
                          {
                            text: 'View my Refills',
                            url: 'https://mypharmacy.shoppersdrugmart.ca/en/login',
                          },
                        ],
                        callToAction: {
                          text: 'Manage my Refills ',
                          url: 'https://mypharmacy.shoppersdrugmart.ca/en/login',
                        },
                      },
                      {
                        hierarchy: ['Health & Pharmacy', 'Prescriptions', 'Manage Prescriptions'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5SD3ig7Y6CL5w92N3KirjL',
                        id: '5SD3ig7Y6CL5w92N3KirjL',
                        l4Data: [
                          {
                            text: 'Transfer Prescriptions',
                            url: 'https://mypharmacy.shoppersdrugmart.ca/en/transfer-prescription?nav=/en/transfer-prescription',
                          },
                          {
                            text: 'Add a Family member',
                            url: 'https://mypharmacy.shoppersdrugmart.ca/en/lp/caregiver?nav=/en/lp/caregiver',
                          },
                          {
                            text: 'Submit Prescriptions by Photo',
                            url: 'https://access.eforms.shoppersdrugmart.ca/prescriptionphoto/',
                          },
                          {
                            text: 'Medication Locator',
                            url: 'https://www.shoppersdrugmart.ca/en/health/medication-inventory-locator/',
                          },
                        ],
                        callToAction: {
                          text: 'Manage Prescriptions',
                          url: 'https://www.shoppersdrugmart.ca/health/digital-pharmacy?nav=/health/digital-pharmacy',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Health & Pharmacy', 'Pharmacist Consultations'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '1h0WPivkddQjZ2XTmMNW57',
                id: '1h0WPivkddQjZ2XTmMNW57',
                callToAction: {
                  text: 'Pharmacist Consultations',
                  url: 'https://www.shoppersdrugmart.ca/health/pharmacist-consultations?nav=/health/pharmacist-consultations',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2srq8qZamkmJRooOInDMgn',
                    id: '2srq8qZamkmJRooOInDMgn',
                    l3Data: [
                      {
                        hierarchy: ['Health & Pharmacy', 'Pharmacist Consultations', 'All Pharmacist Consultations'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7dzkIxtgHYkwBsRBSGHqlM',
                        id: '7dzkIxtgHYkwBsRBSGHqlM',
                        l4Data: [
                          {
                            text: 'UTI',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-consultations/urinary-tract-infection-treatment?nav=/health/pharmacist-consultations/urinary-tract-infection-treatment',
                          },
                          {
                            text: 'Pink Eye',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-consultations/pink-eye-treatment?nav=/health/pharmacist-consultations/pink-eye-treatment',
                          },
                          {
                            text: 'Acne',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-consultations/acne-treatment?nav=/health/pharmacist-consultations/acne-treatment',
                          },
                          {
                            text: 'Cold Sores',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-consultations/cold-sores-treatment?nav=/health/pharmacist-consultations/cold-sores-treatment',
                          },
                          {
                            text: 'Skin Rash',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-consultations/skin-rash-treatment?nav=/health/pharmacist-consultations/skin-rash-treatment',
                          },
                          {
                            text: 'Travel Consultation',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-vaccinations-injections/book-travel-clinic-vaccine-appointment-near-me?nav=/health/pharmacist-vaccinations-injections/book-travel-clinic-vaccine-appointment-near-me',
                          },
                          {
                            text: 'Think Pharmacist',
                            url: 'https://www.shoppersdrugmart.ca/health/wellness/think-pharmacist?nav=/health/wellness/think-pharmacist',
                          },
                          {
                            text: 'Pharmacy Care Clinics',
                            url: 'https://www.shoppersdrugmart.ca/health/wellness/pharmacy-care-clinics?lang=en&nav=/health/wellness/pharmacy-care-clinics',
                          },
                        ],
                        callToAction: {
                          text: 'All Pharmacist Consultations',
                          url: 'https://www.shoppersdrugmart.ca/health/pharmacist-consultations?nav=/health/pharmacist-consultations',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Health & Pharmacy', 'Vaccinations & Injections'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: 'vNftvnphG0xQZ3VuIHKs1',
                id: 'vNftvnphG0xQZ3VuIHKs1',
                callToAction: {
                  text: 'Vaccinations & Injections',
                  url: 'https://www.shoppersdrugmart.ca/health/pharmacist-vaccinations-injections?nav=/health/pharmacist-vaccinations-injections',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'ao6mtAhB897P5PeaocfCF',
                    id: 'ao6mtAhB897P5PeaocfCF',
                    l3Data: [
                      {
                        hierarchy: ['Health & Pharmacy', 'Vaccinations & Injections', 'All Vaccinations & Injections'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6iy0yRiJNEBM1bWwGLFJV6',
                        id: '6iy0yRiJNEBM1bWwGLFJV6',
                        l4Data: [
                          {
                            text: 'Flu Shot',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-vaccinations-injections/book-flu-shot-vaccine-appointment-near-me?nav=/health/pharmacist-vaccinations-injections/book-flu-shot-vaccine-appointment-near-me',
                          },
                          {
                            text: 'Covid-19 Vaccine',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-vaccinations-injections/book-covid-19-vaccine-appointment-near-me?nav=/health/pharmacist-vaccinations-injections/book-covid-19-vaccine-appointment-near-me',
                          },
                          {
                            text: 'Shingles Vaccine',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-vaccinations-injections/book-shingles-vaccine-appointment-near-me',
                          },
                          {
                            text: 'Pneumonia Vaccine',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-vaccinations-injections/book-pneumonia-vaccine-appointment-near-me',
                          },
                          {
                            text: 'Travel Consultation',
                            url: 'https://www.shoppersdrugmart.ca/health/pharmacist-vaccinations-injections/book-travel-clinic-vaccine-appointment-near-me?nav=/health/pharmacist-vaccinations-injections/book-travel-clinic-vaccine-appointment-near-me',
                          },
                        ],
                        callToAction: {
                          text: 'All Vaccinations & Injections',
                          url: 'https://www.shoppersdrugmart.ca/health/pharmacist-vaccinations-injections?nav=/health/pharmacist-vaccinations-injections',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Health & Pharmacy', 'Diabetes Management'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '6Q3rHyzmPln1XDMwxTdjYB',
                id: '6Q3rHyzmPln1XDMwxTdjYB',
                callToAction: {
                  text: 'Diabetes Management',
                  url: 'https://www.shoppersdrugmart.ca/health/diabetes?nav=/health/diabetes',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6TMYmjW4FQdttCB5xPRvIz',
                    id: '6TMYmjW4FQdttCB5xPRvIz',
                    l3Data: [
                      {
                        hierarchy: ['Health & Pharmacy', 'Diabetes Management', 'All Diabetes Management'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '01SeGPPFPZ4YMlTu3y5qmq',
                        id: '01SeGPPFPZ4YMlTu3y5qmq',
                        l4Data: [
                          {
                            text: 'A1c Test',
                            url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/on-site-testing?nav=/en/health-and-wellness/pharmacy-services/on-site-testing',
                          },
                          {
                            text: 'Cholesterol Test',
                            url: 'https://www.shoppersdrugmart.ca/en/health-and-wellness/pharmacy-services/on-site-testing?nav=/en/health-and-wellness/pharmacy-services/on-site-testing',
                          },
                        ],
                        callToAction: {
                          text: 'All Diabetes Management',
                          url: 'https://www.shoppersdrugmart.ca/health/diabetes?nav=/health/diabetes',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Health & Pharmacy', 'Health Services'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3a6osmXQIxAbxom88oJ95R',
                id: '3a6osmXQIxAbxom88oJ95R',
                callToAction: {
                  text: 'Health Services',
                  url: 'https://www.shoppersdrugmart.ca/health/wellness/think-pharmacist?nav=/health/wellness/think-pharmacist',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5nxHqF6ELLZlc0trXQCVPX',
                    id: '5nxHqF6ELLZlc0trXQCVPX',
                    l3Data: [
                      {
                        hierarchy: ['Health & Pharmacy', 'Health Services', 'Lifemark Physio & Rehab'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6L9M1c7S5lF3ygslXNxRDp',
                        id: '6L9M1c7S5lF3ygslXNxRDp',
                        l4Data: [
                          {
                            text: 'Physiotherapy',
                            url: 'https://www.shoppersdrugmart.ca/health/lifemark/physiotherapy-treatment-near-me',
                          },
                          {
                            text: 'Massage Therapy',
                            url: 'https://www.shoppersdrugmart.ca/health/lifemark/massage-therapy-treatment-near-me',
                          },
                          {
                            text: 'Vestibular Rehabilitation',
                            url: 'https://www.shoppersdrugmart.ca/health/lifemark/vestibular-dizziness-balance-therapy-treatment-near-me?lang=en&nav=/health/lifemark/vestibular-dizziness-balance-therapy-treatment-near-me',
                          },
                        ],
                        callToAction: {
                          text: 'Lifemark Physio & Rehab',
                          url: 'https://www.shoppersdrugmart.ca/health/lifemark?nav=/health/lifemark',
                        },
                      },
                      {
                        hierarchy: ['Health & Pharmacy', 'Health Services', 'Get the PC Health app'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2pyV3GhIm3H1tmPys2NsbO',
                        id: '2pyV3GhIm3H1tmPys2NsbO',
                        l4Data: [],
                        callToAction: {
                          text: 'Get the PC Health app',
                          url: 'https://www.pchealth.ca/en/app/',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '5RZmj6DvZSMg1CwerMmcC1',
          id: '5RZmj6DvZSMg1CwerMmcC1',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '5RZmj6DvZSMg1CwerMmcC1',
            id: '5RZmj6DvZSMg1CwerMmcC1',
            callToAction: {
              text: 'Shop',
              url: 'https://www.shoppersdrugmart.ca/Beauty?nav=/shop/beauty',
            },
            iconConfiguration: {
              openIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_more_padded.svg',
              },
              closeIcon: {
                url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/masthead-icons/expand_less_padded.svg',
              },
            },
            l2Data: [
              {
                hierarchy: ['Shop', 'Gifts & Sets'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '1vxjdH4dRlmhK1jztMfjMN',
                id: '1vxjdH4dRlmhK1jztMfjMN',
                callToAction: {
                  text: 'Gifts & Sets',
                  url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Beauty-Gifts-%26-Sets/c/FS-GiftSets?nav=%2Fshop%2FCategories%2FGiftsAndSets',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4WvfXDRAxPS0h6OSE2agGL',
                    id: '4WvfXDRAxPS0h6OSE2agGL',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', ' Shop All Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3crdbwFxaR0JSpGb2uzcfS',
                        id: '3crdbwFxaR0JSpGb2uzcfS',
                        l4Data: [],
                        callToAction: {
                          text: ' Shop All Gifts & Sets',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Beauty-Gifts-&-Sets/c/FS-GiftSets?nav=/shop/categories/Beauty/Beauty-Gifts-&-Sets/c/FS-GiftSets',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', 'New Arrivals'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7F47vovGsiY8W33tUErUg2',
                        id: '7F47vovGsiY8W33tUErUg2',
                        l4Data: [],
                        callToAction: {
                          text: 'New Arrivals',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-GiftSets?nav=/shop/collections/new-arrivals/c/FS-new-arrivals-FS-GiftSets',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', 'Makeup Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5L1OiJv0zU9VDulBFoBTKA',
                        id: '5L1OiJv0zU9VDulBFoBTKA',
                        l4Data: [],
                        callToAction: {
                          text: 'Makeup Gifts & Sets',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Makeup/MakeupGiftsAndSets/c/FS-G100?nav=/shop/categories/Makeup/MakeupGiftsAndSets',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'bNJ9wQQAN6pbSu0LkAnV1',
                    id: 'bNJ9wQQAN6pbSu0LkAnV1',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', 'Skin Care Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5YBpm9PcAdnQEHWwP3bPHc',
                        id: '5YBpm9PcAdnQEHWwP3bPHc',
                        l4Data: [],
                        callToAction: {
                          text: 'Skin Care Gifts & Sets',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/SkinCareGiftsAndSets/c/FS-G200?nav=/shop/categories/SkinCare/SkinCareGiftsAndSets',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', 'Hair Care Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3TLIOKTAHdsKHnU0AoLmAK',
                        id: '3TLIOKTAHdsKHnU0AoLmAK',
                        l4Data: [],
                        callToAction: {
                          text: 'Hair Care Gifts & Sets',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Beauty-Gifts-%2526-Sets/Hair-Care-Gifts-%2526-Sets/c/FS-G400?nav=%252Fshop%252FCategories%252FGiftsAndSets%252FHairCareGiftsAndSets',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '1y9cUOtPGtZajLJyUwQLY4',
                    id: '1y9cUOtPGtZajLJyUwQLY4',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', 'Fragrance Gifts & Sets'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3s8RdpWWD7HPZ3wW3dv0j7',
                        id: '3s8RdpWWD7HPZ3wW3dv0j7',
                        l4Data: [],
                        callToAction: {
                          text: 'Fragrance Gifts & Sets',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Fragrance/FragranceGiftsAndSets/c/FS-G300?nav=/shop/categories/Fragrance/FragranceGiftsAndSets',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', "Men's Gifts & Sets"],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6cpu472gsLEVBL6Ml0SdAf',
                        id: '6cpu472gsLEVBL6Ml0SdAf',
                        l4Data: [],
                        callToAction: {
                          text: "Men's Gifts & Sets",
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Beauty-Gifts-%2526-Sets/Men%2527s-Gifts-%2526-Sets/c/FS-G600?nav=%252Fshop%252FCategories%252FMen%252FMensGiftsAndSets',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', 'Candles & Home Gifts'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'AiUUD0LynvvSWZ0Od7QGw',
                        id: 'AiUUD0LynvvSWZ0Od7QGw',
                        l4Data: [],
                        callToAction: {
                          text: 'Candles & Home Gifts',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/Candles-%2526-Home-Gifts/c/CandlesAndHomeGifts?nav=beauty%252Fgiftsandsets%252Fcandlesandhomegifts',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5brNJvsjBusFOkzZ6k8ryq',
                    id: '5brNJvsjBusFOkzZ6k8ryq',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Gifts & Sets', 'Digital Gift Cards'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3KfYGGNfqxLGGFHJtsmG6U',
                        id: '3KfYGGNfqxLGGFHJtsmG6U',
                        l4Data: [],
                        callToAction: {
                          text: 'Digital Gift Cards',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/digital-gift-cards/c/DigitalGiftCards?nav=/shop/categories/digital-gift-cards/c/DigitalGiftCards',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Shop', 'Beauty'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2gY2uvfKtfIHhkrGFwrMqq',
                id: '2gY2uvfKtfIHhkrGFwrMqq',
                callToAction: {
                  text: 'Beauty',
                  url: 'https://www.shoppersdrugmart.ca/Beauty?nav=/shop/beauty',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'ZRfd20zFtkOQtGXXkXc7O',
                    id: 'ZRfd20zFtkOQtGXXkXc7O',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Beauty', 'Shop All Beauty'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6m99WrLTdDZneOXxnwDxDx',
                        id: '6m99WrLTdDZneOXxnwDxDx',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Beauty',
                          url: 'https://www.shoppersdrugmart.ca/Beauty?nav=/shop/beauty',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Beauty', 'Skin Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '67y2PwRKnmE4ySe7G3SsIp',
                        id: '67y2PwRKnmE4ySe7G3SsIp',
                        l4Data: [
                          {
                            text: 'New Arrivals',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-SkinCare?nav=/shop/collections/new-arrivals/c/FS-new-arrivals-FS-SkinCare',
                          },
                          {
                            text: 'Best Sellers',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/best-sellers/skin-care-best-sellers/c/BeautySkinCareBestSellers?nav=%2Fshop%2FCategories%2FSkinCare%2FBestSellers',
                          },
                          {
                            text: 'Luxury Skin Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/luxury-skin-care/c/LuxurySkinCare?nav=/shop/collections/luxury-skin-care/c/LuxurySkinCare',
                          },
                          {
                            text: 'Face Moisturizers',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/FaceMoisturizers/c/FS-S100?nav=/shop/categories/SkinCare/FaceMoisturizers',
                          },
                          {
                            text: 'Sun Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/skin-care/sun-care/c/FS-S700?nav=/shop/categories/beauty/skin-care/sun-care/c/FS-S700',
                          },
                          {
                            text: 'Cleansers',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/Cleansers/c/FS-S200?nav=/shop/categories/SkinCare/Cleansers',
                          },
                          {
                            text: 'Treatments',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/Treatments/c/FS-S300?nav=/shop/categories/SkinCare/Treatments',
                          },
                          {
                            text: 'Masks',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/Masks/c/FS-S400?nav=shop/categories/SkinCare/Masks',
                          },
                          {
                            text: 'Eye Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/EyeCare/c/FS-S500?nav=/shop/categories/SkinCare/EyeCare',
                          },
                          {
                            text: 'Lip Balm & Treatment',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/LipBalmAndTreatment/c/FS-S999?nav=/shop/categories/SkinCare/LipBalmAndTreatment',
                          },
                          {
                            text: 'Self Tanners',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/SelfTanners/c/FS-S900?nav=/shop/categories/SkinCare/SelfTanners',
                          },
                          {
                            text: 'Skin Care Tools & Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/SkinCareToolsAndAccessories/c/FS-T200?nav=/shop/categories/SkinCare/SkinCareToolsAndAccessories',
                          },
                          {
                            text: 'Body Skin Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/skincare/bodycare/c/FS-S666?nav=/shop/categories/beauty/skincare/bodycare/c/FS-S666',
                          },
                          {
                            text: 'Skin Care Refills',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Skincare-Refills/c/FS-S600?nav=/shop/categories/Skincare-Refills/c/FS-S600',
                          },
                        ],
                        callToAction: {
                          text: 'Skin Care',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/skin-care/c/FS-SkinCare?nav=%2Fshop%2FCategories%2FSkinCare',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6s0GkmJkTkANrPA1qV0VHf',
                    id: '6s0GkmJkTkANrPA1qV0VHf',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Beauty', 'Makeup'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '293iXGEZP6xH8SEazLTYAn',
                        id: '293iXGEZP6xH8SEazLTYAn',
                        l4Data: [
                          {
                            text: 'New Arrivals',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-MakeUp',
                          },
                          {
                            text: 'Best Sellers',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/best-sellers/makeup-best-sellers/c/BeautyMakeupBestSellers?nav=%2Fshop%2FCategories%2FMakeup%2FBestSellers',
                          },
                          {
                            text: 'Luxury Makeup',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/luxury-makeup/c/LuxuryMakeup?nav=/shop/collections/luxury-makeup/c/LuxuryMakeup',
                          },
                          {
                            text: 'Face',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Makeup/Face/c/FS-A100?nav=/shop/categories/Makeup/Face',
                          },
                          {
                            text: 'Eyes',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Makeup/Eyes/c/FS-A200?nav=%252Fshop%252FCategories%252FMakeup%252FEyes',
                          },
                          {
                            text: 'Makeup Palettes',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Makeup/MakeupPalettes/c/FS-A600?nav=/shop/categories/Makeup/MakeupPalettes',
                          },
                          {
                            text: 'Lips',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Makeup/Lips/c/FS-A300?nav=/shop/categories/Makeup/Lips',
                          },
                          {
                            text: 'Nails',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Makeup/Nails/c/FS-Nails?nav=/shop/categories/Makeup/Nails',
                          },
                          {
                            text: 'Makeup Brushes & Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Makeup/MakeupBrushesAndAccessories/c/FS-T100?nav=/shop/categories/Makeup/MakeupBrushesAndAccessories',
                          },
                          {
                            text: 'Makeup Refills',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/Makeup-Refills/c/FS-A700?nav=/shop/collections/Makeup-Refills/',
                          },
                        ],
                        callToAction: {
                          text: 'Makeup',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Makeup/c/FS-MakeUp?nav=/shop/categories/Makeup',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Beauty', 'Fragrance'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6WWYEKahzIDivazg1vbPQS',
                        id: '6WWYEKahzIDivazg1vbPQS',
                        l4Data: [
                          {
                            text: 'New Arrivals',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-Fragrance?nav=/shop/collections/new-arrivals/c/FS-new-arrivals-FS-Fragrance',
                          },
                          {
                            text: 'Best Sellers',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/best-sellers/fragrance-best-sellers/c/BeautyFragranceBestSellers?nav=%2Fshop%2FCategories%2FFragrance%2FBestSellers',
                          },
                          {
                            text: 'Luxury Fragrance',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/luxury-fragrance/c/LuxuryFragrance?nav=/shop/collections/luxury-fragrance',
                          },
                          {
                            text: 'Fragrance For Her',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Fragrance/FragranceforHer/c/FS-F100?nav=/shop/categories/Fragrance/FragranceforHer',
                          },
                          {
                            text: 'Fragrance for Him',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Fragrance/FragranceforHim/c/FS-F200?nav=/shop/categories/Fragrance/FragranceforHim',
                          },
                          {
                            text: 'Home Scents & Candles',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Fragrance/HomeScentsAndCandles/c/FS-F300?nav=/shop/categories/Fragrance/HomeScentsAndCandles',
                          },
                          {
                            text: 'Rollerballs & Travel Size',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Fragrance/RollerballsAndTravelSize/c/FS-F500?nav=/shop/categories/Fragrance/RollerballsAndTravelSize',
                          },
                          {
                            text: 'Fragrance Refills',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Fragrance/Fragrance-Refills/c/FS-F600?nav=shop/categories/Beauty/Fragrance/Fragrance-Refills',
                          },
                        ],
                        callToAction: {
                          text: 'Fragrance',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Fragrance/c/FS-Fragrance?nav=/shop/categories/Fragrance',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5vfComdRLO62BEIFnChQoj',
                    id: '5vfComdRLO62BEIFnChQoj',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Beauty', 'Hair Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4Ec2IsQJaTTFMSLMx1vSb6',
                        id: '4Ec2IsQJaTTFMSLMx1vSb6',
                        l4Data: [
                          {
                            text: 'New Arrivals',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-HairCare?nav=/shop/collections/new-arrivals/c/FS-new-arrivals-FS-HairCare',
                          },
                          {
                            text: 'Best Sellers',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/best-sellers/hair-care-best-sellers/c/BeautyHairBestSellers?nav=/shop/collections/best-sellers/hair-care-best-sellers',
                          },
                          {
                            text: 'Luxury Hair Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/luxury-hair-care/c/LuxuryHairCare?nav=/shop/collections/luxury-hair-care/',
                          },
                          {
                            text: 'Shampoos & Conditioners',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/ShampoosAndConditioners/c/FS-H100?nav=/shop/categories/HairCare/ShampoosAndConditioners',
                          },
                          {
                            text: 'Hair Type',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?hairType=NORMAL&page=1&hairType=THICK&hairType=HAIR&hairType=FINE&hairType=LENGTHENING&hairType=DRY&hairType=COLOR_TREATED&hairType=DAMAGED&hairType=CURLY&hairType=MEDIUM&hairType=THICKENING&hairType=VOLUMIZING',
                          },
                          {
                            text: 'Hair Concern',
                            url: 'https://www.shoppersdrugmart.cashop/categories/beauty/hair-care/c/FS-HairCare?concern=HAIR&page=1&concern=SLEEK&concern=SENSITIVITY_AND_REDNESS&concern=COLOR_TREATED&concern=TEXTURIZING&concern=HYDRATION&concern=HEAT_PROTECTION&concern=DAMAGED&concern=DANDRUFF_CONTROL&concern=SHINE&concern=SKIN_CARE&concern=ECZEMA&concern=BRIGHTENING&concern=VOLUMIZING&concern=HYDRATING&concern=ANTI_AGING&concern=FRIZZ_CONTROL&concern=DRYNESS&concern=COLOR_PROTECTION',
                          },
                          {
                            text: 'Hair Colour',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairColour/c/FS-H400?nav=/shop/categories/HairCare/HairColour',
                          },
                          {
                            text: 'Styling Products & Treatments',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/StylingProductsAndTreatments/c/FS-H300?nav=/shop/categories/HairCare/StylingProductsAndTreatments',
                          },
                          {
                            text: 'Hair Styling Tools',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairStylingTools/c/FS-T400?nav=/shop/categories/HairCare/HairStylingTools',
                          },
                          {
                            text: 'Hair Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairAccessories/c/FS-T500?nav=/shop/categories/HairCare/HairAccessories',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Care',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/c/FS-HairCare?nav=/shop/categories/HairCare',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Beauty', "Men's"],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1DG9eNB9Xf8jrqADTvikKF',
                        id: '1DG9eNB9Xf8jrqADTvikKF',
                        l4Data: [
                          {
                            text: 'New Arrivals',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-Mens?nav=/shop/collections/new-arrivals/c/FS-new-arrivals-FS-Mens',
                          },
                          {
                            text: 'Best Sellers',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/best-sellers/mens-best-sellers/c/BeautyMensBestSellers?nav=/shop/collections/best-sellers/mens-best-sellers',
                          },
                          {
                            text: 'Shaving For Him',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/men-s/shaving-for-him/c/FS-M500?nav=/shop/categories/beauty/men-s/shaving-for-him',
                          },
                          {
                            text: 'Skin Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/c/FS-M100?nav=shop/categories/beauty/men-s/skin-care-for-him',
                          },
                          {
                            text: 'Fragrance For Him',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Fragrance/Fragrance-for-Him/c/FS-F200?nav=/shop/categories/Beauty/Fragrance/Fragrance-for-Him',
                          },
                          {
                            text: 'Hair For Him',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/men-s/hair-for-him/c/FS-M400?nav=/shop/categories/beauty/men-s/hair-for-him',
                          },
                        ],
                        callToAction: {
                          text: "Men's",
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Mens/c/FS-Mens?nav=/shop/categories/Beauty/Mens&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '6MZKsaaWkNzPIRwNBMH1wN',
                    id: '6MZKsaaWkNzPIRwNBMH1wN',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Beauty', 'Tools & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2kZLuJxvOcNKMGsD5HGVRo',
                        id: '2kZLuJxvOcNKMGsD5HGVRo',
                        l4Data: [
                          {
                            text: 'New Arrivals',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-ToolsAccess?nav=/shop/collections/new-arrivals/c/FS-new-arrivals-FS-ToolsAccess',
                          },
                          {
                            text: 'Best Sellers',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/tools-and-accessories-best-sellers/c/BeautyToolsAndAccessoriesBestSellers?nav=/shop/categories/tools-and-accessories-best-seller',
                          },
                          {
                            text: 'Makeup Brushes & Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Makeup/MakeupBrushesAndAccessories/c/FS-T100?nav=/shop/categories/Makeup/MakeupBrushesAndAccessories',
                          },
                          {
                            text: 'Skin Care Tools & Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/SkinCare/SkinCareToolsAndAccessories/c/FS-T200?nav=/shop/categories/SkinCare/SkinCareToolsAndAccessories',
                          },
                          {
                            text: 'Hair Styling Tools',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairStylingTools/c/FS-T400?nav=/shop/categories/HairCare/HairStylingTools',
                          },
                          {
                            text: 'Hair Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairAccessories/c/FS-T500?nav=/shop/categories/HairCare/HairAccessories',
                          },
                        ],
                        callToAction: {
                          text: 'Tools & Accessories',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/ToolsAndAccessories/c/FS-ToolsAccess?nav=/shop/categories/ToolsAndAccessories',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Beauty', 'Fashion & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6hvEJ7dVpioak3BVo23XDr',
                        id: '6hvEJ7dVpioak3BVo23XDr',
                        l4Data: [
                          {
                            text: 'Fashion',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/fashion/c/FS-PC-T700?nav=/shop/categories/beauty/fashion',
                          },
                          {
                            text: 'Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/accessories/c/FS-PC-T800?nav=/shop/categories/beauty/accessories',
                          },
                        ],
                        callToAction: {
                          text: 'Fashion & Accessories',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/fashion-and-accessories/c/FS-FashAccess??nav=/shop/fashionaccessories',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '1jbukMY99onAJdNdbln7v2',
                    id: '1jbukMY99onAJdNdbln7v2',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/wk09_featurecollecctiontile_p3clinicalskincare_enfr.png',
                      },
                      callToAction: {
                        text: 'Shop Skin Care By Ingredients',
                        url: 'https://www.shoppersdrugmart.ca/shop/categories/Ingredients-that-work/c/2022P3CLSK',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '2tpXTPy8vNhzWUsLZ0Us4x',
                    id: '2tpXTPy8vNhzWUsLZ0Us4x',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/feature-tile/wk05/wk13_featurecollecctiontile_thoughtfulchoices_v1_enfr.png',
                      },
                      callToAction: {
                        text: 'Thoughtful Choices',
                        url: 'https://www.shoppersdrugmart.ca/shop/collections/thoughtful-choices/c/SDM-TC?originalTarget=thoughtful+choices',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '78dMlToZJGXxtJX9yfBbqH',
                    id: '78dMlToZJGXxtJX9yfBbqH',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/wk02_featuredcollectiontile_skintool_b1_en.png',
                      },
                      callToAction: {
                        text: 'Try Our Virtual Skin Analysis Tool',
                        url: 'https://shop.shoppersdrugmart.ca/skindiagnostic',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '6OoVWVBeXdA8fQCx1Df7gt',
                    id: '6OoVWVBeXdA8fQCx1Df7gt',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/vendor-assets/external-partners/feature-collection-images/wk16_featurecollectiontile_quo-tools-accessories_enfr.png',
                      },
                      callToAction: {
                        text: 'Tools & Accessories by Quo Beauty',
                        url: 'https://www.shoppersdrugmart.ca/shop/Quo-Beauty/Categories/Beauty/Tools-%26-Accessories/c/QUO%2CBEAUTY-FS-ToolsAccess?nav=shop%2Fbeauty%2Ftoolsandaccessories%2Ffeaturedcollections%2Ftoolsandaccesssoriesbyquobeauty&sort=trending&page=0',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Shop', 'Luxury Beauty'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '1qULciUDXq3CznZ1luuxx8',
                id: '1qULciUDXq3CznZ1luuxx8',
                callToAction: {
                  text: 'Luxury Beauty',
                  url: 'https://www.shoppersdrugmart.ca/page/luxurybeauty?nav=/page/luxurybeauty',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '40Zv1xu82YAdItYk2RWqvD',
                    id: '40Zv1xu82YAdItYk2RWqvD',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Luxury Beauty', 'Shop Luxury Beauty'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '46WPQn5wGDGIuIS30KdYLv',
                        id: '46WPQn5wGDGIuIS30KdYLv',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop Luxury Beauty',
                          url: 'https://www.shoppersdrugmart.ca/page/luxurybeauty',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '1jAoXOP7kpd9hGEFzYwjlV',
                    id: '1jAoXOP7kpd9hGEFzYwjlV',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Luxury Beauty', 'Featured Brands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'rKn7SZfaJOjNFUHreVKoO',
                        id: 'rKn7SZfaJOjNFUHreVKoO',
                        l4Data: [
                          {
                            text: 'CHANEL',
                            url: 'https://www.shoppersdrugmart.ca/brand/chanel?nav=/brand/chanel',
                          },
                          {
                            text: 'DIOR',
                            url: 'https://www.shoppersdrugmart.ca/page/jadore-dior?nav=/page/jadore-dior',
                          },
                          {
                            text: 'Hermès',
                            url: 'https://www.shoppersdrugmart.ca/shop/hermes/c/HERMES?nav=/shop/hermes/c/HERMES',
                          },
                          {
                            text: 'Guerlain',
                            url: 'https://www.shoppersdrugmart.ca/shop/guerlain/c/GUERLAIN?nav=/shop/guerlain/c/GUERLAIN',
                          },
                          {
                            text: 'Tom Ford',
                            url: 'https://www.shoppersdrugmart.ca/shop/tom-ford/c/TOM%2CFORD?nav=/shop/tom-ford/c/TOM%2CFORD',
                          },
                          {
                            text: 'Yves Saint Laurent',
                            url: 'https://www.shoppersdrugmart.ca/shop/yves-saint-laurent/c/YVES?nav=/shop/yves-saint-laurent',
                          },
                          {
                            text: 'Gucci',
                            url: 'https://www.shoppersdrugmart.ca/shop/gucci/c/GUCCI?nav=/shop/gucci',
                          },
                          {
                            text: 'Lancome',
                            url: 'https://www.shoppersdrugmart.ca/shop/lancome/c/LANCOME?nav=/shop/lancome',
                          },
                          {
                            text: 'Estée Lauder',
                            url: 'https://www.shoppersdrugmart.ca/shop/estee-lauder/c/ESTEE?nav=/shop/estee-lauder',
                          },
                          {
                            text: 'Givenchy',
                            url: 'https://www.shoppersdrugmart.ca/shop/givenchy/c/GIVENCHY?nav=/shop/givenchy/c/GIVENCHY',
                          },
                        ],
                        callToAction: {
                          text: 'Featured Brands',
                          url: 'https://www.shoppersdrugmart.ca/page/luxurybeauty?nav=/page/luxurybeauty',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Shop', 'Digital Gift Cards'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2aLFKq0wjlrRf2f3gHlkWd',
                id: '2aLFKq0wjlrRf2f3gHlkWd',
                callToAction: {
                  text: 'Digital Gift Cards',
                  url: 'https://www.shoppersdrugmart.ca/shop/categories/digital-gift-cards/c/DigitalGiftCards?nav=/shop/categories/digital-gift-cards/c/DigitalGiftCards',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Shop', 'Personal Care'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '2uN9LFH6CLCLpqXE5kOIEp',
                id: '2uN9LFH6CLCLpqXE5kOIEp',
                callToAction: {
                  text: 'Personal Care',
                  url: 'https://www.shoppersdrugmart.ca/Personal-care?nav=/shop/personal-care',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '1MlFf7UeLPn4wj4ae5UGs4',
                    id: '1MlFf7UeLPn4wj4ae5UGs4',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Shop All Personal Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2BmzTdI4ju5cYtRPTR0W1j',
                        id: '2BmzTdI4ju5cYtRPTR0W1j',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Personal Care',
                          url: 'https://www.shoppersdrugmart.ca/Personal-care?nav=/shop/personal-care',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Shop The Wellness Shop'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'HH9n3L28su3taj2hLZBur',
                        id: 'HH9n3L28su3taj2hLZBur',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop The Wellness Shop',
                          url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Bath & Body Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7M5D3KysVHUg2YXmospVOP',
                        id: '7M5D3KysVHUg2YXmospVOP',
                        l4Data: [
                          {
                            text: 'Body Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Bath-%2526-Body-Care/Body-Care/c/FS-PC-B100?nav=/shop/categories/Personal-Care/Bath-%2526-Body-Care/Body-Care',
                          },
                          {
                            text: 'Bath & Shower',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Bath-&-Body-Care/Bath-&-Shower/c/FS-PC-B200?nav=/shop/categories/Personal-Care/Bath-&-Body-Care/Bath-&-Shower',
                          },
                          {
                            text: 'Hand & Foot Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Bath-&-Body-Care/Hand-&-Foot-Care/c/FS-PC-B300?nav=/shop/categories/Personal-Care/Bath-&-Body-Care/Hand-&-Foot-Care',
                          },
                          {
                            text: 'Lip Balms',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/BathBodyCare/LipBalms/c/FS-PC-B400?nav=/shop/categories/BathBodyCare/LipBalms',
                          },
                          {
                            text: 'Deodorant & Antiperspirant',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Bath-&-Body-Care/Deodorant-&-Antiperspirant/c/FS-PC-B500?nav=shop/categories/Personal-Care/Bath-&-Body-Care/Deodorant-&-Antiperspirant',
                          },
                          {
                            text: 'Sun Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/skin-care/sun-care/c/FS-S700?nav=/shop/categories/beauty/skin-care/sun-care',
                          },
                          {
                            text: 'Self Tanners',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Bath-&-Body-Care/Self-Tanners/c/FS-PC-B700?nav=/shop/categories/Personal-Care/Bath-&-Body-Care/Self-Tanners',
                          },
                          {
                            text: 'Bath & Body Gifts & Sets',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Bath-&-Body-Care/Bath-&-Body-Gifts-&-Sets/c/FS-PC-B800?nav=/shop/categories/Personal-Care/Bath-&-Body-Care/Bath-&-Body-Gifts-&-Sets',
                          },
                          {
                            text: 'Bath & Body Care Refills',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/bath-and-body-care/bath-and-body-care-refills/c/FS-PC-B900?nav=/shop/categories/personal-care/bath-and-body-care/bath-and-body-care-refills',
                          },
                        ],
                        callToAction: {
                          text: 'Bath & Body Care',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/bath-and-body-care/c/FS-PC-BathBodyCare?nav=/shop/categories/personal-care/bath-and-body-care',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '61Lsn15yGsw2iUhkdLErPw',
                    id: '61Lsn15yGsw2iUhkdLErPw',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Hair Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '573Dz9sWcQMU91QomBvjbN',
                        id: '573Dz9sWcQMU91QomBvjbN',
                        l4Data: [
                          {
                            text: 'New Arrivals',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-HairCare?nav=/shop/collections/new-arrivals/c/FS-new-arrivals-FS-HairCare',
                          },
                          {
                            text: 'Best Sellers',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/best-sellers/hair-care-best-sellers/c/BeautyHairBestSellers?nav=/shop/collections/best-sellers/hair-care-best-sellers',
                          },
                          {
                            text: 'Luxury Hair Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/luxury-hair-care/c/LuxuryHairCare?nav=/shop/collections/luxury-hair-care/',
                          },
                          {
                            text: 'Shampoos & Conditioners',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/ShampoosAndConditioners/c/FS-H100?nav=/shop/categories/HairCare/ShampoosAndConditioners',
                          },
                          {
                            text: 'Hair Type',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?hairType=NORMAL&page=1&hairType=THICK&hairType=HAIR&hairType=FINE&hairType=LENGTHENING&hairType=DRY&hairType=COLOR_TREATED&hairType=DAMAGED&hairType=CURLY&hairType=MEDIUM&hairType=THICKENING&hairType=VOLUMIZING',
                          },
                          {
                            text: 'Hair Concern',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/hair-care/c/FS-HairCare?concern=HAIR&page=1&concern=SLEEK&concern=SENSITIVITY_AND_REDNESS&concern=COLOR_TREATED&concern=TEXTURIZING&concern=HYDRATION&concern=HEAT_PROTECTION&concern=DAMAGED&concern=DANDRUFF_CONTROL&concern=SHINE&concern=SKIN_CARE&concern=ECZEMA&concern=BRIGHTENING&concern=VOLUMIZING&concern=HYDRATING&concern=ANTI_AGING&concern=FRIZZ_CONTROL&concern=DRYNESS&concern=COLOR_PROTECTION',
                          },
                          {
                            text: 'Hair Colour',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairColour/c/FS-H400?nav=/shop/categories/HairCare/HairColour',
                          },
                          {
                            text: 'Styling Products & Treatments',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/StylingProductsAndTreatments/c/FS-H300?nav=/shop/categories/HairCare/StylingProductsAndTreatments',
                          },
                          {
                            text: 'Hair Styling Tools',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairStylingTools/c/FS-T400?nav=/shop/categories/HairCare/HairStylingTools',
                          },
                          {
                            text: 'Hair Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairAccessories/c/FS-T500?nav=/shop/categories/HairCare/HairAccessories',
                          },
                        ],
                        callToAction: {
                          text: 'Hair Care',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/c/FS-HairCare?nav=/shop/categories/HairCare',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5hbPWtMSGJUAVS2dzEWFmj',
                    id: '5hbPWtMSGJUAVS2dzEWFmj',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Intimate Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1QlbQgxAZxAjkEpWDhy4CQ',
                        id: '1QlbQgxAZxAjkEpWDhy4CQ',
                        l4Data: [
                          {
                            text: 'Pads & Panty Liners',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/intimate-care/pads-and-panty-liners/c/FS-PC-F100?nav=/shop/categories/personal-care/intimate-care/pads-and-panty-liners',
                          },
                          {
                            text: 'Intimate Cleaning & Deodorants',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/intimate-care/intimate-cleaning-and-deodorants/c/FS-PC-F500?nav=/shop/categories/personal-care/intimate-care/intimate-cleaning-and-deodorants',
                          },
                          {
                            text: 'Tampons',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/intimate-care/tampons/c/FS-PC-F200?nav=/shop/categories/personal-care/intimate-care/tampons',
                          },
                          {
                            text: 'Yeast Infection Treatments',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/intimate-care/yeast-infection-treatments/c/FS-PC-F400?nav=/shop/categories/personal-care/intimate-care/yeast-infection-treatments',
                          },
                          {
                            text: 'Cups',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/intimate-care/cups/c/FS-PC-F300?nav=/shop/categories/personal-care/intimate-care/cups',
                          },
                        ],
                        callToAction: {
                          text: 'Intimate Care',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/intimate-care/c/FS-PC-FeminineCare?nav=/shop/categories/personal-care/intimate-care/c/FS-PC-FeminineCare',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Bladder Control & Incontinence'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5BD86NDcrFXoaymhMHRcyq',
                        id: '5BD86NDcrFXoaymhMHRcyq',
                        l4Data: [
                          {
                            text: 'Incontinence Pads & Liners',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/bladder-control-and-incontinence/incontinence-pads-and-liners/c/FS-PC-I100?nav=/shop/categories/personal-care/bladder-control-and-incontinence/incontinence-pads-and-liners',
                          },
                          {
                            text: 'Protective Underwear & Briefs',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Bladder-Control-&-Incontinence/Protective-Underwear-&-Briefs/c/FS-PC-I200?nav=/shop/categories/Personal-Care/Bladder-Control-&-Incontinence/Protective-Underwear-&-Briefs',
                          },
                        ],
                        callToAction: {
                          text: 'Bladder Control & Incontinence',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/bladder-control-and-incontinence/c/FS-PC-BladderControlAndIncontinence?nav=/shop/categories/personal-care/bladder-control-and-incontinence',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Oral Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4yxS7hzu736czukHUCmizm',
                        id: '4yxS7hzu736czukHUCmizm',
                        l4Data: [
                          {
                            text: 'Toothbrushes',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Oral-Care/Toothbrushes/Toothbrushes/c/FS-PC-O102?nav=/shop/categories/Personal-Care/Oral-Care/Toothbrushes/Toothbrushes',
                          },
                          {
                            text: 'Oral Care Products',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/oral-care/oral-care-products/c/FS-PC-O200?nav=/shop/categories/personal-care/oral-care/oral-care-products',
                          },
                        ],
                        callToAction: {
                          text: 'Oral Care',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Oral-Care/c/FS-PC-OralCare?nav=/shop/categories/Personal-Care/Oral-Care&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Shaving & Hair Removal'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4n6yqJsDRKCDhfO6roYyGL',
                        id: '4n6yqJsDRKCDhfO6roYyGL',
                        l4Data: [
                          {
                            text: 'Shaving Creams & Gels',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Mens/Shaving-for-Him/Shaving-Creams-&-Gels/c/FS-PC-M101?nav=/shop/categories/Personal-Care/Mens/Shaving-for-Him/Shaving-Creams-&-Gels',
                          },
                          {
                            text: 'Hair Waxes & Removal Creams',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Shaving&-Hair-Removal/Hair-Waxes-&-Removal-Creams/c/FS-PC-S300?nav=/shop/categories/Personal-Care/Shaving&-Hair-Removal/Hair-Waxes-&-Removal-Creams',
                          },
                          {
                            text: 'Electric Shavers',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/tools-and-accessories/shaving-and-grooming-tools-and-accessories/electric-shavers/c/FS-PC-T301?nav=/shop/categories/personal-care/tools-and-accessories/shaving-and-grooming-tools-and-accessories/electric-shavers',
                          },
                          {
                            text: 'Disposable Razors & Blades',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/tools-and-accessories/shaving-and-grooming-tools-and-accessories/disposable-razors-and-blades/c/FS-PC-T302?nav=/shop/categories/personal-care/tools-and-accessories/shaving-and-grooming-tools-and-accessories/disposable-razors-and-blades',
                          },
                          {
                            text: 'Hair Removal Devices',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/shaving-and-hair-removal/hair-removal-devices/c/FS-PC-S600?nav=/shop/categories/personal-care/shaving-and-hair-removal/hair-removal-devices',
                          },
                          {
                            text: 'Grooming Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Mens/Shaving-for-Him/Grooming-Accessories/c/FS-PC-M106?nav=/shop/categories/Personal-Care/Mens/Shaving-for-Him/Grooming-Accessories',
                          },
                        ],
                        callToAction: {
                          text: 'Shaving & Hair Removal',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Shaving-&-Hair-Removal/c/FS-PC-ShavingAndHairRemoval?nav=/shop/categories/Personal-Care/Shaving-&-Hair-Removal',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '11syFE3ZW5UtU9cnJSehu2',
                    id: '11syFE3ZW5UtU9cnJSehu2',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Personal Care', "Men's"],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1DG9eNB9Xf8jrqADTvikKF',
                        id: '1DG9eNB9Xf8jrqADTvikKF',
                        l4Data: [
                          {
                            text: 'New Arrivals',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/new-arrivals/c/FS-new-arrivals-FS-Mens?nav=/shop/collections/new-arrivals/c/FS-new-arrivals-FS-Mens',
                          },
                          {
                            text: 'Best Sellers',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/best-sellers/mens-best-sellers/c/BeautyMensBestSellers?nav=/shop/collections/best-sellers/mens-best-sellers',
                          },
                          {
                            text: 'Shaving For Him',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/men-s/shaving-for-him/c/FS-M500?nav=/shop/categories/beauty/men-s/shaving-for-him',
                          },
                          {
                            text: 'Skin Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/men-s/skin-care-for-him/c/FS-M100?nav=shop/categories/beauty/men-s/skin-care-for-him',
                          },
                          {
                            text: 'Fragrance For Him',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Fragrance/Fragrance-for-Him/c/FS-F200?nav=/shop/categories/Beauty/Fragrance/Fragrance-for-Him',
                          },
                          {
                            text: 'Hair For Him',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/beauty/men-s/hair-for-him/c/FS-M400?nav=/shop/categories/beauty/men-s/hair-for-him',
                          },
                        ],
                        callToAction: {
                          text: "Men's",
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Beauty/Mens/c/FS-Mens?nav=/shop/categories/Beauty/Mens&sort=trending&page=0',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Tools & Accessories'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'OGMMil9UAWI84rEPDNGw9',
                        id: 'OGMMil9UAWI84rEPDNGw9',
                        l4Data: [
                          {
                            text: 'Hair Styling Tools',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Tools-&-Accessories/Hair-Styling-Tools/c/FS-PC-T100?nav=/shop/categories/Personal-Care/Tools-&-Accessories/Hair-Styling-Tools',
                          },
                          {
                            text: 'Hair Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/HairCare/HairAccessories/c/FS-T500?nav=/shop/categories/HairCare/HairAccessories',
                          },
                          {
                            text: 'Shaving & Grooming Tools & Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Tools-&-Accessories/Shaving-&-Grooming-Tools-&-Accessories/c/FS-PC-T300?nav=/shop/categories/Personal-Care/Tools-&-Accessories/Shaving-&-Grooming-Tools-&-Accessories',
                          },
                          {
                            text: 'Wardrobe Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Tools-&-Accessories/Wardrobe-Accessories/c/FS-PC-T400?nav=/shop/categories/Personal-Care/Tools-&-Accessories/Wardrobe-Accessories',
                          },
                          {
                            text: 'Travel Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Tools-%2526-Accessories/Travel-Accessories/c/FS-PC-T500?nav=/shop/categories/Personal-Care/Tools-%2526-Accessories/Travel-Accessories',
                          },
                          {
                            text: 'Weather Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Tools-&-Accessories/Weather-Accessories/c/FS-PC-T600?nav=/shop/categories/Personal-Care/Tools-&-Accessories/Weather-Accessories',
                          },
                        ],
                        callToAction: {
                          text: 'Tools & Accessories',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/tools-and-accessories/c/FS-PC-ToolsAccess?nav=/shop/categories/personal-care/tools-and-accessories',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Travel Essentials'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4zquk6kKeTy7wBVJv7Xnu5',
                        id: '4zquk6kKeTy7wBVJv7Xnu5',
                        l4Data: [],
                        callToAction: {
                          text: 'Travel Essentials',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Personal-Care/Travel-Sized-Items/c/FS-PC-TravelSizedItems?nav=/shop/categories/Personal-Care/Travel-Sized-Items',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Personal Care', 'Insect & Pest Control'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7MeGD6cCK8tlwMmh6RmH5l',
                        id: '7MeGD6cCK8tlwMmh6RmH5l',
                        l4Data: [],
                        callToAction: {
                          text: 'Insect & Pest Control',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/personal-care/insect-and-pest-control/c/FS-PC-InsectPestControl?nav=/shop/categories/personal-care/insect-and-pest-control',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '4w6WbY7GSq2y8XdGkq6je7',
                    id: '4w6WbY7GSq2y8XdGkq6je7',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2025/beauty/wk-1/personal-care/wk1_featurecollecctiontile_native_enfr.png',
                      },
                      callToAction: {
                        text: 'Native',
                        url: 'https://www.shoppersdrugmart.ca/shop/native/c/NATIVE',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '7c2blqBQZK1AXpC8joGIw9',
                    id: '7c2blqBQZK1AXpC8joGIw9',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2025/beauty/wk-1/personal-care/wk1_featurecollecctiontile_philips_enfr.png',
                      },
                      callToAction: {
                        text: 'Philips Sonicare',
                        url: 'https://www.shoppersdrugmart.ca/shop/philips/c/PHILIPS',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Shop', 'Health'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: 'S9B4X0izIDiWJDWYS0mm9',
                id: 'S9B4X0izIDiWJDWYS0mm9',
                callToAction: {
                  text: 'Health',
                  url: 'https://www.shoppersdrugmart.ca/Health?nav=/shop/health',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '1F91GQCxzx8gxZtF6SBVXa',
                    id: '1F91GQCxzx8gxZtF6SBVXa',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Health', 'Shop All Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'icqY9XzLxeZKfDkZ6Yt10',
                        id: 'icqY9XzLxeZKfDkZ6Yt10',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Health',
                          url: 'https://www.shoppersdrugmart.ca/Health?nav=/shop/health',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Health', 'The Wellness Shop'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3DpkAzIwD7kzhlwPL4Y7VE',
                        id: '3DpkAzIwD7kzhlwPL4Y7VE',
                        l4Data: [
                          {
                            text: 'Vitamins & Supplements',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/health/vitamins-and-supplements/c/FS-HT-VitaminsAndSupplements?nav=/shop/categories/health/vitamins-and-supplements/c/FS-HT-VitaminsAndSupplements',
                          },
                          {
                            text: 'Fitness & Exercise',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSFitnessExercise?nav=/shop/collections/c/TWSFitnessExercise',
                          },
                          {
                            text: 'Sexual Health',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSexualHealth?nav=/shop/collections/c/TWSSexualHealth',
                          },
                          {
                            text: 'Sleep Health',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSSleepHealth?nav=/shop/collections/c/TWSSleepHealth',
                          },
                          {
                            text: 'Relaxation & Stress',
                            url: 'https://www.shoppersdrugmart.ca/shop/collections/c/TWSRelaxationStress?nav=/shop/collections/c/TWSRelaxationStress',
                          },
                        ],
                        callToAction: {
                          text: 'The Wellness Shop',
                          url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Health', 'Medicine & Treatments of Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5kCdadW6jf8qgUcbsSFJrX',
                        id: '5kCdadW6jf8qgUcbsSFJrX',
                        l4Data: [
                          {
                            text: 'Cough, Cold & Flu',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/Cough%252C-Cold-%2526-Flu/c/FS-HT-M100?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FCoughColdAndFlu',
                          },
                          {
                            text: 'Quit Smoking',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/Quit-Smoking/c/FS-HT-M700?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FQuitSmoking',
                          },
                          {
                            text: 'Sleep Aids',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/Sleep-Aids/c/FS-HT-M800?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FSleepAids',
                          },
                          {
                            text: 'Pill Organization',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/Pill-Organization/c/FS-HT-M900?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FPillOrganization',
                          },
                          {
                            text: 'Pain Relief',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/Pain-Relief/c/FS-HT-M200?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FPainRelief',
                          },
                          {
                            text: 'First Aid',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/First-Aid/c/FS-HT-M400?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FFirstAid',
                          },
                          {
                            text: 'Allergy & Sinus',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/Allergy-%2526-Sinus/c/FS-HT-M500?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FAllergyAndSinus',
                          },
                          {
                            text: 'Digestive Health',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/Digestive-Health/c/FS-HT-M300?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDigestiveHealth',
                          },
                          {
                            text: 'Diabetes Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Medicine-%2526-Treatments/Diabetes-Care/c/FS-HT-M600?nav=%252Fshop%252FCategories%252FMedicineAndTreatments%252FDiabeticCare',
                          },
                        ],
                        callToAction: {
                          text: 'Medicine & Treatments of Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/health/medicine-and-treatments/c/FS-HT-MedicineAndTreatments?nav=%2Fshop%2FCategories%2FMedicineAndTreatments',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'mvwi5HirIw2Ta6sSi7ja3',
                    id: 'mvwi5HirIw2Ta6sSi7ja3',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Health', 'Vitamins & Supplements'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '7hIfkG2xrwWu8vyo9av6k0',
                        id: '7hIfkG2xrwWu8vyo9av6k0',
                        l4Data: [
                          {
                            text: 'Vitamins',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Vitamins-%2526-Supplements/Vitamins/c/FS-HT-V100?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements',
                          },
                          {
                            text: 'Supplements',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Vitamins-%2526-Supplements/Supplements/c/FS-HT-V200?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FSupplements',
                          },
                          {
                            text: 'Mineral Supplements',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Vitamins-%2526-Supplements/Mineral-Supplements/c/FS-HT-V300?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FMineralSupplements',
                          },
                          {
                            text: 'Nutrition & Weight Management',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Vitamins-%2526-Supplements/Nutrition-%2526-Weight-Management/c/FS-HT-V400?nav=%252Fshop%252FCategories%252FVitaminsAndSupplements%252FMineralSupplements',
                          },
                        ],
                        callToAction: {
                          text: 'Vitamins & Supplements',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/health/vitamins-and-supplements/c/FS-HT-VitaminsAndSupplements?nav=%2Fshop%2FCategories%2FMedicineAndTreatments',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Health', 'Shop All Sexual Wellness & Family Planning'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2KTYFI8BG7mq9jgwRd2SAZ',
                        id: '2KTYFI8BG7mq9jgwRd2SAZ',
                        l4Data: [
                          {
                            text: 'Sexual Wellness',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Sexual-Wellness-%2526-Family-Planning/Sexual-Wellness/c/FS-HT-S100?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FSexualWellness',
                          },
                          {
                            text: 'Family Planning',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Sexual-Wellness-%2526-Family-Planning/Family-Planning/c/FS-HT-S200?nav=%252Fshop%252FCategories%252FSexualWelnessAndFamilyPlanning%252FFamilyPlanning',
                          },
                        ],
                        callToAction: {
                          text: 'Shop All Sexual Wellness & Family Planning',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/health/sexual-wellness-and-family-planning/c/FS-HT-SexualWelnessAndFamilyPlanning?nav=%2Fshop%2FCategories%2FSexualWelnessAndFamilyPlanning',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Health', 'Eye, Ear & Foot Care of Health'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5JvCshZ16A6yfodO8zHlou',
                        id: '5JvCshZ16A6yfodO8zHlou',
                        l4Data: [
                          {
                            text: 'Eye Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Eye-Ear-Foot-Care/Eye-Care/c/FS-HT-E100?nav=/shop/categories/Health/Eye-Ear-Foot-Care/Eye-Care/c/FS-HT-E100',
                          },
                          {
                            text: 'Ear Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Eye%252C-Ear-%2526-Foot-Care/Ear-Care/c/FS-HT-E200?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FEarCare',
                          },
                          {
                            text: 'Foot Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Eye%252C-Ear-%2526-Foot-Care/Foot-Care/c/FS-HT-E300?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FFootCare',
                          },
                          {
                            text: 'Swim Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Eye%252C-Ear-%2526-Foot-Care/Swim-Accessories/c/FS-HT-E500?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare%252FSwimAccessories',
                          },
                        ],
                        callToAction: {
                          text: 'Eye, Ear & Foot Care of Health',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Eye%252C-Ear-%2526-Foot-Care/c/FS-HT-EyeEarAndFootCare?nav=%252Fshop%252FCategories%252FEyeEarAndFootCare&sort=trending&page=0',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2aYrfL6u75WWb5Q0WBohVu',
                    id: '2aYrfL6u75WWb5Q0WBohVu',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Health', 'Home Health Care & Fitness'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5rsd0DHVh7G7kZ8Z47DQWs',
                        id: '5rsd0DHVh7G7kZ8Z47DQWs',
                        l4Data: [
                          {
                            text: 'Compression Hosiery & Stockings',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Home-Health-Care-&-Fitness/Compression-Hosiery-&-Stockings/c/FS-HT-H100?nav=/shop/categories/Health/Home-Health-Care-&-Fitness/Compression-Hosiery-&-Stockings',
                          },
                          {
                            text: 'Scales, Pulse Oximeter & Pedometers',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Home-Health-Care-%2526-Fitness/Scales%252C-Pulse-Oximeter-%2526-Pedometers/c/FS-HT-H300?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FScalesPulseOximeterAndPedometers',
                          },
                          {
                            text: 'Body Wellness & Massagers',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Home-Health-Care-%2526-Fitness/Body-Wellness-%2526-Massagers/c/FS-HT-H500?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FBodyWellnessAndMassagers',
                          },
                          {
                            text: 'Bathroom Safety',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Home-Health-Care-%2526-Fitness/Bathroom-Safety/c/FS-HT-H600?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FBathroomSafety',
                          },
                          {
                            text: 'Blood Pressure Monitors',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Home-Health-Care-%2526-Fitness/Blood-Pressure-Monitors/c/FS-HT-H400?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FBloodPressureMonitors',
                          },
                          {
                            text: 'Cane & Crutches',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Home-Health-Care-%2526-Fitness/Cane-%2526-Crutches/c/FS-HT-H200?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FCaneAndCrutches',
                          },
                          {
                            text: 'Fitness Equipment & Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Health/Home-Health-Care-%2526-Fitness/Fitness-Equipment-%2526-Accessories/c/FS-HT-H700?nav=%252Fshop%252FCategories%252FHomeHealthCare%252FFitnessEquipmentAndAccessories',
                          },
                        ],
                        callToAction: {
                          text: 'Home Health Care & Fitness',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/health/home-health-care-and-fitness/c/FS-HT-HomeHealthCare?nav=%2Fshop%2FCategories%2FHomeHealthCare',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '5nxHqF6ELLZlc0trXQCVPX',
                    id: '5nxHqF6ELLZlc0trXQCVPX',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Health', 'Lifemark Physio & Rehab'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6L9M1c7S5lF3ygslXNxRDp',
                        id: '6L9M1c7S5lF3ygslXNxRDp',
                        l4Data: [
                          {
                            text: 'Physiotherapy',
                            url: 'https://www.shoppersdrugmart.ca/health/lifemark/physiotherapy-treatment-near-me',
                          },
                          {
                            text: 'Massage Therapy',
                            url: 'https://www.shoppersdrugmart.ca/health/lifemark/massage-therapy-treatment-near-me',
                          },
                          {
                            text: 'Vestibular Rehabilitation',
                            url: 'https://www.shoppersdrugmart.ca/health/lifemark/vestibular-dizziness-balance-therapy-treatment-near-me?lang=en&nav=/health/lifemark/vestibular-dizziness-balance-therapy-treatment-near-me',
                          },
                        ],
                        callToAction: {
                          text: 'Lifemark Physio & Rehab',
                          url: 'https://www.shoppersdrugmart.ca/health/lifemark?nav=/health/lifemark',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Health', 'Get the PC Health app'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2pyV3GhIm3H1tmPys2NsbO',
                        id: '2pyV3GhIm3H1tmPys2NsbO',
                        l4Data: [],
                        callToAction: {
                          text: 'Get the PC Health app',
                          url: 'https://www.pchealth.ca/en/app/',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '4EYkk4p69PP4GL5CiEK4VR',
                    id: '4EYkk4p69PP4GL5CiEK4VR',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/wk17/health/wk01_featurecollecctiontile_ppe_enfr.png',
                      },
                      callToAction: {
                        text: 'Personal Protective Equipment ',
                        url: 'https://www.shoppersdrugmart.ca/shop/categories/health/medicine-and-treatments/first-aid/c/FS-HT-M400',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '7G3yWqiEF96kr06nbAv6IS',
                    id: '7G3yWqiEF96kr06nbAv6IS',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2024/SDM/the-wellness-shop/misc-site-assets/46_navigation-feature-tile---for-masthead-menu/wkxx_featurecollecctiontile_thewellnessshop_sdm_en.png',
                      },
                      callToAction: {
                        text: 'The Wellness Shop',
                        url: 'https://www.shoppersdrugmart.ca/page/the-wellness-shop?nav=/shop/wellnesshop',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: '2dJI6KG3W07vCCTyyQAGb2',
                    id: '2dJI6KG3W07vCCTyyQAGb2',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2025/beauty/wk-1/health/wk1_featurecollecctiontile_tylenol_enfr.png',
                      },
                      callToAction: {
                        text: 'Tylenol Pain Relief',
                        url: 'https://www.shoppersdrugmart.ca/shop/tylenol/c/TYLENOL',
                      },
                    },
                  },
                  {
                    componentId: 'discoveryTileComponent',
                    contentId: 'd2RtzgflUjskldk5FFbCD',
                    id: 'd2RtzgflUjskldk5FFbCD',
                    data: {
                      image: {
                        url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2025/beauty/wk-1/health/wk1_featurecollecctiontile_emergenc_enfr.png',
                      },
                      callToAction: {
                        text: 'Emergen-C Multivitamin',
                        url: 'https://www.shoppersdrugmart.ca/shop/emergen-c/c/EMERGEN%2CC',
                      },
                    },
                  },
                ],
              },
              {
                hierarchy: ['Shop', 'Baby & Child'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '5NQOCmUvUVs0LLv12spD5o',
                id: '5NQOCmUvUVs0LLv12spD5o',
                callToAction: {
                  text: 'Baby & Child',
                  url: 'https://www.shoppersdrugmart.ca/Baby-and-child?nav=/shop/babyandchild',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '43VQtx8fFKMxj5mLD3oJRj',
                    id: '43VQtx8fFKMxj5mLD3oJRj',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Baby & Child', 'Shop All Baby & Child'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1XzVGEgZ1FOKX0BirgVy9Z',
                        id: '1XzVGEgZ1FOKX0BirgVy9Z',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Baby & Child',
                          url: 'https://www.shoppersdrugmart.ca/Baby-and-child?nav=/shop/babyandchild',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Baby & Child', 'Baby & Child Care'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3YHz2UcZEF4yVkq7CF6pzp',
                        id: '3YHz2UcZEF4yVkq7CF6pzp',
                        l4Data: [
                          {
                            text: 'Diapers & Wipes',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Baby-%2526-Child/Baby-%2526-Child-Care/Diapers-%2526-Wipes/c/FS-BC-B100?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FDiapersAndWipes',
                          },
                          {
                            text: 'Bath & Body Care',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/baby-and-child/baby-and-child-care/bath-and-body-care/c/FS-BC-B200?nav=/shop/categories/baby-and-child/baby-and-child-care/bath-and-body-care/c/FS-BC-B200',
                          },
                          {
                            text: "Children's Health Care",
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Baby-%2526-Child/Baby-%2526-Child-Care/Children%2527s-Health-Care/c/FS-BC-B300?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FChildrensHealthCare',
                          },
                          {
                            text: 'Childproofing & Safety',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Baby-%2526-Child/Baby-%2526-Child-Care/Childproofing-%2526-Safety/c/FS-BC-B400?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FChildproofingAndSafety',
                          },
                          {
                            text: 'Clothing & Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Baby-%2526-Child/Baby-%2526-Child-Care/Clothing-%2526-Accessories/c/FS-BC-B600?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FClothingAndAccessories',
                          },
                          {
                            text: 'Baby Gifts & Sets',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Baby-%2526-Child/Baby-%2526-Child-Care/Baby-Gifts-%2526-Sets/c/FS-BC-B700?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FBabyGiftsAndSets',
                          },
                        ],
                        callToAction: {
                          text: 'Baby & Child Care',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/baby-and-child/baby-and-child-care/c/FS-BC-BabyCare?nav=/shop/categories/baby-and-child/baby-and-child-care/c/FS-BC-BabyCare',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '4qogX1NlnR702sH4N3ojs6',
                    id: '4qogX1NlnR702sH4N3ojs6',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Baby & Child', 'Feeding & Formula'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4JDpsq79bAZY66GGtT1zja',
                        id: '4JDpsq79bAZY66GGtT1zja',
                        l4Data: [
                          {
                            text: 'Baby Food & Formula',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Baby-%2526-Child/Feeding-%2526-Formula/Baby-Food-%2526-Formula/c/FS-BC-F100?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FBabyFoodAndFormula',
                          },
                          {
                            text: 'Nursing & Mealtime',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Baby-%2526-Child/Feeding-%2526-Formula/Nursing-%2526-Mealtime/c/FS-BC-F200?nav=%252Fshop%252FCategories%252FFeedingAndFormula%252FNurisngAndMealtime',
                          },
                          {
                            text: 'New Infant Formula',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/baby-and-child/feeding-and-formula/baby-food-and-formula/formula/c/FS-BC-F101?nav=/shop/categories/baby-and-child/feeding-and-formula/baby-food-and-formula/formula/c/FS-BC-F101',
                          },
                        ],
                        callToAction: {
                          text: 'Feeding & Formula',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/baby-and-child/feeding-and-formula/c/FS-BC-BabyFeeding?nav=%2Fshop%2FCategories%2FBabyAndChildCare',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Baby & Child', 'Toys'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1l7YtQm3SyfPOqicutkWOi',
                        id: '1l7YtQm3SyfPOqicutkWOi',
                        l4Data: [],
                        callToAction: {
                          text: 'Toys',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Baby-%2526-Child/Baby-%2526-Child-Care/Toys/c/FS-BC-B500?nav=%252Fshop%252FCategories%252FBabyAndChildCare%252FToys',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Shop', 'Home & Electronics'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7MVR7oaWO0wng22VlVFCuI',
                id: '7MVR7oaWO0wng22VlVFCuI',
                callToAction: {
                  text: 'Home & Electronics',
                  url: 'https://www.shoppersdrugmart.ca/Electronics?nav=/shop/homeandelectronics',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '7evxuGeicnXNl023TF5Eyr',
                    id: '7evxuGeicnXNl023TF5Eyr',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Home & Electronics', 'Shop All Electronics'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4SMxnoi1MPGGnHaJqx8n1O',
                        id: '4SMxnoi1MPGGnHaJqx8n1O',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Electronics',
                          url: 'https://www.shoppersdrugmart.ca/Electronics?nav=/shop/homeandelectronics',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Home & Electronics', 'Electronics'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1IW1pUfMFi3pXRcy5ySOXi',
                        id: '1IW1pUfMFi3pXRcy5ySOXi',
                        l4Data: [
                          {
                            text: 'Audio',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Electronics/Other-Electronics/Audio/c/FS-EL-301?nav=%252Fshop%252FCategories%252FElectronics%252FAudio',
                          },
                          {
                            text: 'Batteries',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Electronics/Batteries/c/FS-EL-100?nav=%252Fshop%252FCategories%252FElectronics%252FBatteries',
                          },
                          {
                            text: 'Cameras & Phone Accessories',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Electronics/Cameras%252C-Phones-%2526-Accessories/c/FS-EL-400?nav=%252Fshop%252FCategories%252FElectronics%252FCamerasAndPhoneAccessories',
                          },
                          {
                            text: 'Computers & Smart Tech',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Electronics/Computers-%2526-Smart-Tech/c/FS-EL-500?nav=%252Fshop%252FCategories%252FElectronics%252FComputersAndSmartTech',
                          },
                          {
                            text: 'Video Games',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Electronics/Video-Games/c/FS-EL-200?nav=%252Fshop%252FCategories%252FElectronics%252FVideoGames',
                          },
                        ],
                        callToAction: {
                          text: 'Electronics',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/home-and-electronics/electronics/c/FS-EL-Electronics?nav=%2Fshop%2FCategories%2FElectronics',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '75xy63ehe8TmNA9duyxBqV',
                    id: '75xy63ehe8TmNA9duyxBqV',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Home & Electronics', 'Home & Lifestyle'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: 'LkfaXZoEFqzejK2ib27JW',
                        id: 'LkfaXZoEFqzejK2ib27JW',
                        l4Data: [],
                        callToAction: {
                          text: 'Home & Lifestyle',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/home-and-electronics/home-and-lifestyle/c/FS-EL-Homeware?nav=%2Fshop%2FCategories%2FHomeAndElectronics%2FHomeware',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Home & Electronics', 'Bedding'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2qo7k1NTiEBh9aicqdFrfo',
                        id: '2qo7k1NTiEBh9aicqdFrfo',
                        l4Data: [
                          {
                            text: 'Pillows',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Bedding-%2526-Mattresses/Pillows/c/FS-EL-B200?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FPillows',
                          },
                          {
                            text: 'Weighted Blankets & Covers',
                            url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Bedding-%2526-Mattresses/Weighted-Blankets-%2526-Covers/c/FS-EL-B300?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FWeightedBlankets',
                          },
                        ],
                        callToAction: {
                          text: 'Bedding',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/home-and-electronics/bedding-and-mattresses/c/FS-EL-Bedding?nav=%2Fshop%2FCategories%2FHomeAndElectronics%2FBedding',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Home & Electronics', 'Greeting Cards'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '53kDB0E6bFeWADoRWuJk6F',
                        id: '53kDB0E6bFeWADoRWuJk6F',
                        l4Data: [],
                        callToAction: {
                          text: 'Greeting Cards',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Home-%2526-Lifestyle/Greeting-Cards/c/FS-EL-H500?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware%252FGreetingsCards',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2nTbgjXvRj9gQhLVQSGBCr',
                    id: '2nTbgjXvRj9gQhLVQSGBCr',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'Home & Electronics', 'Appliances'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4Et9gYwUw7H9XVwfWGWH69',
                        id: '4Et9gYwUw7H9XVwfWGWH69',
                        l4Data: [],
                        callToAction: {
                          text: 'Appliances',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Home-%2526-Lifestyle/Appliances/c/FS-EL-H600?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware%252FAppliances',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Home & Electronics', 'Seasonal Shop'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '6zjXBRNsiVCko6WqTHEqfJ',
                        id: '6zjXBRNsiVCko6WqTHEqfJ',
                        l4Data: [],
                        callToAction: {
                          text: 'Seasonal Shop',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/Home-%2526-Electronics/Home-%2526-Lifestyle/Seasonal-Shop/c/FS-EL-H100?nav=%252Fshop%252FCategories%252FHomeAndElectronics%252FHomeware%252FSeasonalDecor&sort=newest&page=0&lang=en',
                        },
                      },
                      {
                        hierarchy: ['Shop', 'Home & Electronics', 'Digital Gift Cards'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1NRTzcXXrJfw3u9O2I0BZP',
                        id: '1NRTzcXXrJfw3u9O2I0BZP',
                        l4Data: [],
                        callToAction: {
                          text: 'Digital Gift Cards',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/digital-gift-cards/c/DigitalGiftCards?nav=/shop/categories/digital-gift-cards/c/DigitalGiftCards',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Shop', 'All Brands'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '3uJNbzCJefTmsa74enziCk',
                id: '3uJNbzCJefTmsa74enziCk',
                callToAction: {
                  text: 'All Brands',
                  url: 'https://www.shoppersdrugmart.ca/all-brands',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2safDAUjbTJ8yEh7YIbXKc',
                    id: '2safDAUjbTJ8yEh7YIbXKc',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'All Brands', 'Shop All Brands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2KK8iJdRO5DT5PyneaFToi',
                        id: '2KK8iJdRO5DT5PyneaFToi',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Brands',
                          url: 'https://www.shoppersdrugmart.ca/all-brands',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2qY1ZYsFfgFmR0jDML2n5R',
                    id: '2qY1ZYsFfgFmR0jDML2n5R',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'All Brands', 'Exclusively at Shoppers Drug Mart'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '1v1vjpmVvtfD5EbNqPo8ci',
                        id: '1v1vjpmVvtfD5EbNqPo8ci',
                        l4Data: [
                          {
                            text: 'About-Face',
                            url: 'https://www.shoppersdrugmart.ca/shop/about-face/c/ABOUT%2CFACE?sort=trending&page=0',
                          },
                          {
                            text: 'Black Radiance',
                            url: 'https://www.shoppersdrugmart.ca/shop/black-radiance/c/BLACK%2CRADIANCE?sort=trending&page=0&nav=/shop/black-radiance',
                          },
                          {
                            text: 'Bondi Sands',
                            url: 'https://www.shoppersdrugmart.ca/shop/bondi-sands/c/BONDI%2CSANDS?sort=trending&page=0&nav=/shop/bondi-sands',
                          },
                          {
                            text: 'BYOMA',
                            url: 'https://www.shoppersdrugmart.ca/shop/byoma/c/BYOMA?sort=trending&page=0&nav=/shop/byoma',
                          },
                          {
                            text: 'Cocokind',
                            url: 'https://www.shoppersdrugmart.ca/shop/cocokind/c/COCOKIND?sort=trending&page=0?nav=/shop/cocokind',
                          },
                          {
                            text: 'Darphin',
                            url: 'https://www.shoppersdrugmart.ca/shop/Darphin/c/DARPHIN?sort=trending&page=0&nav=/shop/Darphin',
                          },
                          {
                            text: 'Essence',
                            url: 'https://www.shoppersdrugmart.ca/shop/essence/c/ESSENCE?sort=trending&page=0&nav=/shop/essence',
                          },
                          {
                            text: 'Indeed Laboratories',
                            url: 'https://www.shoppersdrugmart.ca/shop/indeed-laboratories/c/INDEED?sort=trending&page=0&nav=/shop/indeed-laboratories',
                          },
                          {
                            text: 'Jason Wu',
                            url: 'https://www.shoppersdrugmart.ca/shop/jason-wu/c/JASON%2CWU?sort=trending&page=0&nav=/shop/jason-wu',
                          },
                          {
                            text: 'Kylie Cosmetics',
                            url: 'https://www.shoppersdrugmart.ca/shop/kylie-cosmetics/c/KYLIE?sort=trending&page=0&nav=/shop/kylie-cosmetics',
                          },
                          {
                            text: 'Madeup Beauty',
                            url: 'https://www.shoppersdrugmart.ca/shop/madeup-beauty/c/MADEUP%2CBEAUTY?sort=trending&page=0&nav=shop/madeup-beauty',
                          },
                          {
                            text: 'Maëlys',
                            url: 'https://www.shoppersdrugmart.ca/shop/maelys/c/MAELYS?sort=trending&page=0&nav=/shop/maelys',
                          },
                          {
                            text: 'my iN.gredients',
                            url: 'https://www.shoppersdrugmart.ca/shop/my-in-gredients/c/MY%2CINGREDIENTS?sort=trending&page=0&nav=/shop/my-in-gredients',
                          },
                        ],
                        callToAction: {
                          text: 'Exclusively at Shoppers Drug Mart',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/exclusives/c/Exclusives?sort=trending&page=0&q=%3Atrending',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '78yHdLhAKysvbKRnYi6j23',
                    id: '78yHdLhAKysvbKRnYi6j23',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'All Brands', 'Exclusively at Shoppers Drug Mart'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3lWXhCdxWtBqsOedPYd2ZR',
                        id: '3lWXhCdxWtBqsOedPYd2ZR',
                        l4Data: [
                          {
                            text: 'No7',
                            url: 'https://www.shoppersdrugmart.ca/shop/no7/c/NO.7?sort=trending&page=0&nav=/shop/no7',
                          },
                          {
                            text: 'Naked Sundays',
                            url: 'https://www.shoppersdrugmart.ca/shop/naked-sundays/c/NAKED%2CSUNDAYS?sort=trending&page=0&nav=/shop/naked-sundays',
                          },
                          {
                            text: 'Naturium',
                            url: 'https://www.shoppersdrugmart.ca/shop/naturium/c/NATURIUM?sort=trending&page=0&nav=/shop/naturium',
                          },
                          {
                            text: 'Pacifica',
                            url: 'https://www.shoppersdrugmart.ca/shop/pacifica-beauty/c/PACIFICA?sort=trending&page=0&nav=/shop/pacifica-beauty',
                          },
                          {
                            text: 'Pixi',
                            url: 'https://www.shoppersdrugmart.ca/shop/pixi/c/PIXI?sort=trending&page=0&nav=/shop/pixi',
                          },
                          {
                            text: 'Quo Beauty',
                            url: 'https://www.shoppersdrugmart.ca/shop/quo-beauty/c/QUO%2CBEAUTY?sort=trending&page=0&nav=/shop/quo-beauty',
                          },
                          {
                            text: 'Raw Sugar',
                            url: 'https://www.shoppersdrugmart.ca/shop/raw-sugar/c/RAW%2CSUGAR?sort=trending&page=0&nav=/shop/raw-sugar',
                          },
                          {
                            text: 'r.e.m. beauty',
                            url: 'https://www.shoppersdrugmart.ca/shop/r-e-m-beauty/c/R.E.M.BEAUTY?sort=trending&page=0&nav=/shop/r-e-m-beauty',
                          },
                          {
                            text: 'Revolution',
                            url: 'https://www.shoppersdrugmart.ca/shop/revolution/c/REVOLUTION?sort=trending&page=0&nav=/shop/revolution',
                          },
                          {
                            text: 'Soap & Glory',
                            url: 'https://www.shoppersdrugmart.ca/shop/soap-and-glory/c/M-SOAP%26GLORY?sort=trending&page=0&nav=/shop/soap-and-glory',
                          },
                          {
                            text: 'SVR',
                            url: 'https://www.shoppersdrugmart.ca/shop/SVR/c/SVR%2CLABORATORIE?sort=trending&page=0&nav=/shop/SVR',
                          },
                          {
                            text: 'Truly Beauty',
                            url: 'https://www.shoppersdrugmart.ca/shop/truly-beauty/c/TRULY?sort=trending&page=0&nav=/shop/truly-beauty',
                          },
                          {
                            text: 'Winky Lux',
                            url: 'https://www.shoppersdrugmart.ca/shop/winky-lux/c/WINKY%2CLUX?sort=trending&page=0&nav=/shop/winky-lux',
                          },
                          {
                            text: 'Yves Rocher',
                            url: 'https://www.shoppersdrugmart.ca/shop/yves-rocher/c/YVES%2CROCHER?sort=trending&page=0&nav=/shop/yves-rocher',
                          },
                        ],
                        callToAction: {
                          text: 'Exclusively at Shoppers Drug Mart',
                          url: 'https://www.shoppersdrugmart.ca/shop/collections/exclusives/c/Exclusives?sort=trending&page=0&q=%3Atrending',
                        },
                      },
                    ],
                  },
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: 'MfRoljnkKce5pkMT8vVzm',
                    id: 'MfRoljnkKce5pkMT8vVzm',
                    l3Data: [
                      {
                        hierarchy: ['Shop', 'All Brands', 'Most Searched Brands'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5BddzJs0B7XQ19lO2IKijn',
                        id: '5BddzJs0B7XQ19lO2IKijn',
                        l4Data: [
                          {
                            text: 'Byoma',
                            url: 'https://www.shoppersdrugmart.ca/shop/byoma/c/BYOMA?nav=/brands/byomamostsearched',
                          },
                          {
                            text: 'Clarins',
                            url: 'https://www.shoppersdrugmart.ca/shop/clarins/c/CLARINS?nav=/brands/clarinsmostsearched',
                          },
                          {
                            text: 'DIOR',
                            url: 'https://www.shoppersdrugmart.ca/page/jadore-dior?nav=/brands/diormostsearched',
                          },
                          {
                            text: 'Dyson',
                            url: 'https://www.shoppersdrugmart.ca/shop/dyson/c/DYSON?nav=/brands/dysonmostsearched',
                          },
                          {
                            text: 'Elf Cosmetics',
                            url: 'https://www.shoppersdrugmart.ca/shop/e-l-f-cosmetics/c/ELF%2CCOSMETICS?nav=/brands/elfcosmeticsmostsearched',
                          },
                          {
                            text: 'Lancome',
                            url: 'https://www.shoppersdrugmart.ca/shop/lancome/c/LANCOME?nav=/brands/lancomemostsearched',
                          },
                          {
                            text: 'La Roche Posay',
                            url: 'https://www.shoppersdrugmart.ca/shop/la-roche-posay/c/LA%2CROCHE%2CPOSAY?nav=/brands/larocheposaymostsearched',
                          },
                          {
                            text: 'L’Oréal Paris',
                            url: 'https://www.shoppersdrugmart.ca/shop/l-oreal-paris/c/L%27OREAL?nav=/brands/lorealparismostsearched',
                          },
                          {
                            text: 'Naturium',
                            url: 'https://www.shoppersdrugmart.ca/shop/naturium/c/NATURIUM?nav=/brands/naturiummostsearched',
                          },
                          {
                            text: 'NYX',
                            url: 'https://www.shoppersdrugmart.ca/shop/nyx-professional-makeup/c/NYX?nav=/brands/nyxmostsearched',
                          },
                        ],
                        callToAction: {
                          text: 'Most Searched Brands',
                          url: 'https://www.shoppersdrugmart.ca/all-brands?lang=en',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
            ],
          },
        },
        {
          componentId: 'categoryNavigationDropdownComponent',
          contentId: '2BcjlKpVtPQJtx4LCvZFUX',
          id: '2BcjlKpVtPQJtx4LCvZFUX',
          data: {
            componentId: 'categoryNavigationDropdownComponent',
            contentId: '2BcjlKpVtPQJtx4LCvZFUX',
            id: '2BcjlKpVtPQJtx4LCvZFUX',
            callToAction: {
              text: 'Flyer & Deals',
              url: 'https://www.shoppersdrugmart.ca/flyer?lang=en',
            },
            l2Data: [
              {
                hierarchy: ['Flyer & Deals', 'See Flyer & Deals'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '7pzkjritwncBmvaraMrUvc',
                id: '7pzkjritwncBmvaraMrUvc',
                callToAction: {
                  text: 'See Flyer & Deals',
                  url: 'https://www.shoppersdrugmart.ca/Promotions',
                },
                columns: [],
                discoveryContent: [],
              },
              {
                hierarchy: ['Flyer & Deals', 'Offers'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4fVKbSifVagEWyCSIJkJ5p',
                id: '4fVKbSifVagEWyCSIJkJ5p',
                callToAction: {
                  text: 'Offers',
                  url: 'https://www.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FSale&q=%3Atrending%3AshowInStock%3Atrue&page=0&sort=trending',
                },
                columns: [
                  {
                    componentId: 'categoryNavigationDropdownColumn',
                    contentId: '2qQ6od3gGDR43z5N3FM0Rc',
                    id: '2qQ6od3gGDR43z5N3FM0Rc',
                    l3Data: [
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'Shop All Offers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '2m0ityEDCWVg5SS6Mxfcqf',
                        id: '2m0ityEDCWVg5SS6Mxfcqf',
                        l4Data: [],
                        callToAction: {
                          text: 'Shop All Offers',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FSale&q=%3Atrending%3AshowInStock%3Atrue&page=0&sort=trending',
                        },
                      },
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'Sale'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5xKfqqX8X8RJ51DoIrdbmy',
                        id: '5xKfqqX8X8RJ51DoIrdbmy',
                        l4Data: [],
                        callToAction: {
                          text: 'Sale',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FSale&q=%3Atrending%3AshowInStock%3Atrue&page=1&sort=trending&promotions=Sale',
                        },
                      },
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'Clearance'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '4JVaOe6an35PwgF42cJUdx',
                        id: '4JVaOe6an35PwgF42cJUdx',
                        l4Data: [],
                        callToAction: {
                          text: 'Clearance',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FSale&q=%3Atrending%3AshowInStock%3Atrue&page=1&sort=trending&promotions=Clearance',
                        },
                      },
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'Gift With Purchase'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '3NAj7kGTPAnfNtXz36sN3Y',
                        id: '3NAj7kGTPAnfNtXz36sN3Y',
                        l4Data: [],
                        callToAction: {
                          text: 'Gift With Purchase',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FSale&q=%3Atrending%3AshowInStock%3Atrue&page=1&sort=trending&promotions=Gift%2Bwith%2BPurchase',
                        },
                      },
                      {
                        hierarchy: ['Flyer & Deals', 'Offers', 'PC Optimum Offers'],
                        componentId: 'categoryNavigationDropdownL3',
                        contentId: '5VZkaScF13hWAqWlrXqDa1',
                        id: '5VZkaScF13hWAqWlrXqDa1',
                        l4Data: [],
                        callToAction: {
                          text: 'PC Optimum Offers',
                          url: 'https://www.shoppersdrugmart.ca/shop/categories/offers/c/FS-Offers?nav=%2Fshop%2FCategories%2FOffers%2FSale&q=%3Atrending%3AshowInStock%3Atrue&page=1&sort=trending&promotions=PC%2BOptimum%2BOffer',
                        },
                      },
                    ],
                  },
                ],
                discoveryContent: [],
              },
              {
                hierarchy: ['Flyer & Deals', 'Flyer'],
                componentId: 'categoryNavigationDropdownL2',
                contentId: '4jQNc0nBxPH1cNva4Zxf2S',
                id: '4jQNc0nBxPH1cNva4Zxf2S',
                callToAction: {
                  text: 'Flyer',
                  url: 'https://www.shoppersdrugmart.ca/flyer?lang=en',
                },
                columns: [],
                discoveryContent: [],
              },
            ],
          },
        },
      ],
    },
    region: 'ON',
  },
  footer: {
    typeId: 'pageLayoutSingleColumn',
    sections: {
      mainContentCollection: {
        components: [
          {
            componentId: 'miniInfoCardContainerComponent',
            contentId: '6Nonl4BFjD9nyjOv2w2qIO',
            id: '6Nonl4BFjD9nyjOv2w2qIO',
            data: {
              containerType: 'GRID',
              upNumber: '4UP',
              miniInfoCards: [
                {
                  contentId: '4sugiShS6FUK2mKTybfMga',
                  headline: 'Free delivery',
                  displayText1: 'on orders of $50+, excluding digital gift cards',
                  image: {
                    url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/benefits-icon/Shipping.png',
                  },
                  displayText1Alignment: 'Top',
                  imageAlignment: 'Left',
                },
                {
                  contentId: '3SJYtIyWHcpnW0cEDAbUJC',
                  headline: 'Free returns by mail',
                  displayText1: 'on all orders',
                  image: {
                    url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/benefits-icon/return.png',
                  },
                  imageAlignment: 'Left',
                },
                {
                  contentId: '2C7FGfC5snkrhTH0gtAqGO',
                  headline: '3 free samples',
                  displayText1: 'with every Luxury Beauty order',
                  image: {
                    url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/benefits-icon/samples.png',
                  },
                },
                {
                  contentId: '7Gw4auPatlPTKwo2FghgYq',
                  headline: 'Earn & redeem',
                  displayText1: 'PC Optimum points',
                  image: {
                    url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2023/evergreen/masthead/benefits-icon/PCO.png',
                  },
                },
              ],
            },
          },
          {
            componentId: 'footerContentComponent',
            contentId: '5zICDFcQjtEDEakvNiSNK9',
            id: '5zICDFcQjtEDEakvNiSNK9',
          },
        ],
      },
    },
  },
};

export const themeTokens = {
  uppercase: 'uppercase',
  spBase: '0.167rem',
  sp00: '0rem',
  sp01: '0.167rem',
  sp02: '0.334rem',
  sp03: '0.668rem',
  sp04: '1.002rem',
  sp05: '1.336rem',
  sp06: '1.67rem',
  sp07: '2.004rem',
  sp08: '2.672rem',
  sp09: '3.34rem',
  sp10: '6.68rem',
  br00: '0px',
  br01: '2px',
  br02: '4px',
  br03: '8px',
  br04: '12px',
  br05: '20px',
  borderWidth: {
    none: '0px',
    default: '1px',
    medium: '2px',
    thick: '4px',
    extraThick: '8px',
  },
  compact: '100%',
  normal: '120%',
  tall: '150%',
  none: 'none',
  underline: 'underline',
  strikethrough: 'line-through',
  iconSize: {
    xxs: '1rem',
    xs: '1.167rem',
    s: '1.333rem',
    m: '1.667rem',
    l: '2rem',
    xl: '3rem',
    xxl: '4rem',
  },
  tracking: {
    small: '-1%',
    medium: '0',
    large: '1%',
    extraLarge: '2%',
  },
  br06: '32px',
  breakpoint: {
    xlg: '114.667rem',
    lg: '85.333rem',
    md: '75rem',
    sm: '54.167rem',
    xsm: '34.5rem',
    xxlg: '416.667rem',
  },
  siteMargin: {
    xlgDefault: '10.667rem',
    xlgNarrow: '21.333rem',
    lgDefault: '2.667rem',
    lgNarrow: '10.667rem',
    mdDefault: '2.667rem',
    mdNarrow: '10.667rem',
    smDefault: '2.667rem',
    smNarrow: '2.667rem',
    xsmDefault: '1.333rem',
    xlgWide: '3.333rem',
  },
  '25Percent': '0.25',
  '50Percent': '0.5',
  '75Percent': '0.75',
  contentMaxWidth: {
    xlg: '93.333rem',
    lg: '74.667rem',
    md: '67rem',
    sm: '45.333rem',
    xsm: '31.833rem',
    xlgWide: '108rem',
  },
  imageSize: {
    '40': '3.333rem',
    '50': '4.167rem',
    '60': '5rem',
    '70': '5.833rem',
    '80': '6.667rem',
    '90': '7.5rem',
    '100': '8.333rem',
    '110': '9.167rem',
    '120': '10rem',
    '130': '10.833rem',
    '140': '11.667rem',
    '180': '15rem',
  },
  medium: '130%',
  '95Percent': '95%',
  '90Opacity': '0.9',
  '80Opacity': '0.8',
  '100Opacity': '100%',
  base: '1.333rem',
  regular: '300',
  common: {
    common01Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    common01Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    common02Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    common02Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    common03Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    common03Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    common04Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    common04Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    common05Regular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    common05Bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    common01: '1.5rem',
    common02: '1.333rem',
    common03: '1.166rem',
    common04: '1rem',
    common05: '0.833rem',
  },
  progressBar: {
    borderRadius: '100px',
    gap: '0.333rem',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    height: '0.833rem',
    heightSmall: '0.333rem',
  },
  progressStamp: {
    borderAdded: '2px',
    gap: '0.333rem',
    numberText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    stamperBorderRadius: '12px',
    stamperSize: '2rem',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    trackerBorder: '4px',
    missedSize: '1.5rem',
    segmentWidth: '0.417rem',
  },
  brand: {
    beautyBoutique: '#62B5E5',
    discount: '#003DA5',
    jf: '#DA3C12',
    market: '#333333',
    murale: '#8977BA',
    sdm: '#CB333B',
    pco: '#F9DADA',
  },
  palette: {
    black: '#2F2F2F',
    darkestGrey: '#757575',
    darkGrey: '#B3B3B3',
    midGrey: '#CACACA',
    lightGrey: '#EAEAEA',
    lightestGrey: '#F5F5F5',
    white: '#ffffff',
    uiRed: '#970012',
    sdmRed: '#CB333B',
    lightRed: '#FFEBEA',
    sdmBlue: '#62B5E5',
    uiLightBlue: '#D3EEF8',
    lightBlue: '#EFF8FC',
    navyBlue: '#1B3B4A',
    healthBlue: '#003C71',
    thoughtfulChoices: '#007FAA',
    uiGreen: '#00853D',
    lightGreen: '#E7F3ED',
    uiOrange: '#FFAE58',
    lightOrange: '#FFF0E0',
    offBlack: '#333333',
    none: 'transparent',
    borderGrey: '#cccccc',
    uiDarkGreen: '#467e1b',
    pcoRed: '#AC342A',
    pcRed: '#e1251b',
    pcDarkRed: '#AE0000',
    pcxGreen: '#00817F',
    pcxDarkGreen: '#006766',
    pcxPurple: '#685BC7',
    pcxOrange: '#FFA06A',
    pcxLightBlue: '#A3D1EB',
    darkOrange: '#D57F07',
    goldenrod: '#FFEFD1',
    slimeGreen: '#F9FFD1',
    skyBlue: '#E2F4FF',
    powderPink: '#F6E6E6',
    pcxTeal: '#BDDDD9',
    pcxLightPurple: '#ECEAFF',
    charcoal: '#403840',
    yellow: '#FED700',
  },
  offertileheader: {
    logoSize: '2.167rem',
    paddingVertical: '0.167rem',
    gap: '0.167rem',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    iconSize: '1.333rem',
  },
  shoppingListItem: {
    rewardTextActive: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    rewardTextExpired: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    name: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    nameChecked: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '0',
      textDecoration: 'line-through',
    },
    subcopy: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.166rem',
      letterSpacing: '0',
    },
    brand: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    currentPrice: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    currentPriceExpired: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    completionIndicator: '2.667rem',
    image: '3.333rem',
    completionIndicatorIcon: '2rem',
    removeIcon: '1.167rem',
    border: '1px',
    paddingLarge: '1.336rem',
    paddingMedium: '0.668rem',
    emptyMaxWidth: '50.667rem',
    completionIndicatorRadius: '100px',
    imageInactive: '0.25',
  },
  atc: {
    borderRadius: '20px',
    fullBorderRadius: '32px',
    backgroundDefault: '#2F2F2F',
    backgroundPressed: '#757575',
    borderDefault: '#2F2F2F',
    borderPressed: '#757575',
    disabled: '#B3B3B3',
    onDefault: '#ffffff',
    onPressed: '#ffffff',
    onDisabled: '#CACACA',
    buttonMaxWidth: '20rem',
    incrementBorderRadius: '12px',
    disabledIconOpacity: '0.4',
    inputWidth: '4.167rem',
    inputWidthCart: '3.333rem',
    compactSize: '3.333rem',
  },
  desktop: {
    large01: '3.666rem',
    large02: '2.666rem',
    large03: '1.999rem',
    large04: '1.666rem',
    desktopLarge01Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '3.666rem',
      letterSpacing: '1%',
    },
    desktopLarge02Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    desktopLarge02Regular: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '300',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '0',
    },
    desktopLarge03Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    desktopLarge04Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
  },
  tablet: {
    large01: '2.666rem',
    large02: '1.999rem',
    large03: '1.666rem',
    large04: '1.5rem',
    tabletLarge01Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    tabletLarge02Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    tabletLarge02Regular: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '300',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '0',
    },
    tabletLarge03Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    tabletLarge04Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
  },
  mobile: {
    large01: '2.333rem',
    large02: '1.999rem',
    large03: '1.666rem',
    large04: '1.5rem',
    mobileLarge01Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.333rem',
      letterSpacing: '1%',
    },
    mobileLarge02Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    mobileLarge02Regular: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '300',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '0',
    },
    mobileLarge03Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    mobileLarge04Bold: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
  },
  icon: {
    default: '#2F2F2F',
    onPrimary: '#ffffff',
    onInteractive: '#ffffff',
    onSecondary: '#2F2F2F',
    disabled: '#CACACA',
    star: '#2F2F2F',
    error: '#970012',
    warning: '#FFAE58',
    info: '#1B3B4A',
    success: '#00853D',
    accent1: '#CB333B',
    accent2: '#2F2F2F',
    accent3: '#62B5E5',
    interactive: '#2F2F2F',
    deemphasized: '#757575',
    onActionPrimary: '#ffffff',
    onActionPrimaryPressed: '#EAEAEA',
    onActionPrimaryDisabled: '#cccccc',
    onActionSecondary: '#2F2F2F',
    onActionSecondaryPressed: '#B3B3B3',
    onActionSecondaryDisabled: '#cccccc',
    onActionTertiary: '#2F2F2F',
    onActionTertiaryPressed: '#757575',
    onActionTertiaryDisabled: '#cccccc',
    deal: '#CB333B',
  },
  interactive: {
    default: '#757575',
    pressed: '#B3B3B3',
    selected: '#2F2F2F',
    selectedPressed: '#757575',
    backgroundSelected: '#2F2F2F',
    backgroundSelectedPressed: '#757575',
    backgroundDefault: '#F5F5F5',
    backgroundDefaultPressed: '#ffffff',
    error: '#970012',
    disabled: '#CACACA',
    onSelected: '#ffffff',
    deemphasized: '#B3B3B3',
    menuButton: '#ffffff',
  },
  border: {
    default: '#CACACA',
    hover: '#757575',
    onPrimary: '#ffffff',
    onSecondary: '#2F2F2F',
    disabled: '#CACACA',
    emphasized: '#2F2F2F',
    success: '#00853D',
    error: '#970012',
    stepper: '#EAEAEA',
    sponsored: '#EFF8FC',
    accent1: '#CB333B',
    accent2: '#2F2F2F',
    accent3: '#62B5E5',
    actionPrimary: '#2F2F2F',
    actionPrimaryPressed: '#757575',
    actionPrimaryDisabled: '#cccccc',
    actionSecondary: '#B3B3B3',
    actionSecondaryPressed: '#2F2F2F',
    actionSecondaryDisabled: '#cccccc',
    actionTertiary: 'transparent',
    actionTertiaryPressed: 'transparent',
    actionTertiaryDisabled: 'transparent',
  },
  headline: {
    default: '#2F2F2F',
    onPrimary: '#ffffff',
    desktop01: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '3.666rem',
      letterSpacing: '1%',
    },
    desktop02: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    desktop03: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    desktop04: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    tablet01: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    tablet02: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    tablet03: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    tablet04: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    mobile01: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.333rem',
      letterSpacing: '1%',
    },
    mobile02: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    mobile03: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    mobile04: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
  },
  text: {
    default: '#2F2F2F',
    onPrimary: '#ffffff',
    onSecondary: '#2F2F2F',
    onTertiary: '#2F2F2F',
    onInteractive: '#ffffff',
    disabled: '#757575',
    deemphasized: '#757575',
    error: '#970012',
    success: '#00853D',
    sale: '#CB333B',
    save: '#00817F',
    deal: '#CB333B',
    accent1: '#2F2F2F',
    accent2: '#CB333B',
    accent3: '#62B5E5',
    onActionPrimary: '#ffffff',
    onActionPrimaryPressed: '#EAEAEA',
    onActionPrimaryDisabled: '#cccccc',
    onActionSecondary: '#2F2F2F',
    onActionSecondaryPressed: '#CACACA',
    onActionSecondaryDisabled: '#cccccc',
    onActionTertiary: '#2F2F2F',
    onActionTertiaryPressed: '#757575',
    onActionTertiaryDisabled: '#cccccc',
  },
  background: {
    default: '#ffffff',
    mid: '#F5F5F5',
    brand: '#CB333B',
    accent1: '#FFEBEA',
    accent2: '#EAEAEA',
    accent3: '#EFF8FC',
    accent3Subdued: '#F5F5F5',
    accent3SubduedPressed: '#EAEAEA',
    dark: '#2F2F2F',
    darkPressed: '#757575',
    accent4: '#EFF8FC',
  },
  surface: {
    default: '#ffffff',
    banner: '#EAEAEA',
    secondary: '#F5F5F5',
    disabled: '#EAEAEA',
    success: '#E7F3ED',
    error: '#FFEBEA',
    warning: '#FFF0E0',
    info: '#D3EEF8',
    sponsored: '#EFF8FC',
    overlay: '#000000',
    accent1: '#CB333B',
    accent2: '#2F2F2F',
    accent3: '#62B5E5',
    dark: '#2F2F2F',
  },
  button: {
    smallVertical: '0.334rem',
    smallHorizontal: '0.334rem',
    smallText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    borderRadius: '32px',
    smallIcon: '1.167rem',
    mediumIcon: '1.333rem',
    smallInterior: '0.334rem',
    mediumVertical: '0.334rem',
    mediumHorizontal: '0.668rem',
    mediumInterior: '0.334rem',
    mediumText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    largeIcon: '2rem',
    largeVertical: '0.668rem',
    largeHorizontal: '0.668rem',
    largeInterior: '0.334rem',
    largeText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    borderWidth: '1px',
    smallHeight: '2rem',
    mediumHeight: '2.667rem',
    largeHeight: '3.333rem',
    faveBorderRadius: '32px',
    utilityBorderRadius: '8px',
    utilityBorderRadiusSm: '4px',
    utilityInterior: '0.668rem',
    utilityInteriorSm: '0.334rem',
    utilityText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    utilityTextSm: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    minWidth: '2rem',
    menuHeight: '2.667rem',
    menuBorderRadius: '20px',
    menuInterior: '0.668rem',
    menuButtonText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
  },
  browsesidebar: {
    listHeight: '26.333rem',
    width: '25rem',
    titleText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    sectionHeadingLarge: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    widthSm: '17.5rem',
    activeFilterIcon: '0.667rem',
    sectionHeadingSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  miniproducttile: {
    height: '7.667rem',
    imageSize: '5rem',
    opacity: '0.5',
    border: '1px',
    borderRadius: '8px',
    minWidth: '23.333rem',
    maxWidth: '30.583rem',
  },
  form: {
    mediumLabelText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    mediumVertical: '0.334rem',
    mediumHorizontal: '0.668rem',
    interior: '0.668rem',
    mediumInputText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    largeInputText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    largeVertical: '0.668rem',
    borderRadius: '4px',
    largeHorizontal: '1.002rem',
    largeLabelText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    searchMediumHorizontal: '1.336rem',
    searchBorderRadius: '20px',
    searchlargeHeight: '3.333rem',
    textareaHeight: '7.5rem',
    miniFormButtonAlignment: '1.833rem',
    textMinWidth: '20.833rem',
  },
  element: {
    spacingSmall: '0.334rem',
    spacingMedium: '0.668rem',
    spacingLarge: '1.336rem',
    spacingExtraLarge: '1.67rem',
  },
  content: {
    spacingSmall: '1.336rem',
    spacingMedium: '2.004rem',
    spacingLarge: '3.34rem',
    spacingExtraSmall: '0.668rem',
    spacingExtraLarge: '6.68rem',
    spacingExtraExtraLarge: '8rem',
  },
  control: {
    iconMedium: '1.667rem',
    iconLarge: '2rem',
    mediumLabel: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    largeLabel: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    borderWidth: '1px',
    mediumLabelPadding: '0.334rem',
    largeLabelPadding: '0.167rem',
  },
  checkbox: {
    borderRadius: '2px',
  },
  switch: {
    borderRadius: '20px',
  },
  badge: {
    iconSizeSmall: '1.333rem',
    paddingVertical: '0.167rem',
    paddingHorizontal: '0.668rem',
    borderRadius: '20px',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    iconSizeLarge: '1.333rem',
    stockPadding: '0.167rem',
    stockBorderRadius: '4px',
    badge00: {
      bg: '#2F2F2F',
      text: '#ffffff',
      icon: '#ffffff',
    },
    badge01: {
      bg: '#757575',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge02: {
      bg: '#CB333B',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge03: {
      bg: '#CB333B',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge04: {
      bg: '#1B3B4A',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge05: {
      bg: '#00853D',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge06: {
      bg: '#CB333B',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge07: {
      bg: '#1B3B4A',
      icon: '#ffffff',
      text: '#ffffff',
    },
    badge08: {
      bg: '#007FAA',
      icon: '#ffffff',
      text: '#ffffff',
    },
    height: '1.5rem',
    badge09: {
      bg: '#F5F5F5',
      icon: '#2F2F2F',
      text: '#2F2F2F',
    },
    badge10: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge11: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge12: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge13: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge14: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge15: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge16: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge17: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge18: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge19: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
    badge20: {
      bg: 'transparent',
      icon: 'transparent',
      text: 'transparent',
    },
  },
  dealbadge: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    textLarge: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  variants: {
    selectedRing: '4rem',
    swatch: '3rem',
    paddingDefault: '0.668rem',
    paddingLarge: '1.336rem',
    spacingSmall: '0.334rem',
    borderRadiusLarge: '40px',
    borderRadiusMedium: '10px',
    borderRadiusSmall: '4px',
    swatchDefault: '1px',
    dropdownIcon: '2rem',
    chipText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    dropdownHeight: '25rem',
  },
  price: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bigDeal: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    textSecondary: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    wasText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    wasTextSecondary: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    textPrimary: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    wasTextPrimary: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    descriptor: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    infoTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    infoSubtitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    infoDetails: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    infoBorderWidth: '3px',
    unitText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    textSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    textXSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    wasTextSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    textXSmallDeem: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    wasTextXSmallDeem: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    textSmallDeem: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
  },
  producttile: {
    variant: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    inventory: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    inventoryIcon: '1.167rem',
    title: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    packageSize: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    pcoText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    variantIcon: '1.167rem',
    padding: '0.668rem',
    borderRadius: '4px',
    brandTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    imageHeight: '9.167rem',
    badgeHeight: '1.5rem',
    productInfoHeight: '5.167rem',
    packageSizePadding: '0.167rem',
    memberPrice: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    horizAtlMinHeight: '11.75rem',
    imageWidth: '9.167rem',
    imageAreaWidth: '12.833rem',
    addOnText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    recipientTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    recipientBody: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    gcBorderRadius: '4px',
    textMinWidth: '8.333rem',
    priceMinWidth: '8.333rem',
    priceMaxWidth: '16.667rem',
    columnGap: '2.672rem',
    textMinWidthLg: '21.167rem',
    invBadgeWidth: '4.167rem',
    width: '13.501rem',
  },
  blocks: {
    itemTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    infoDefault: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    title: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    itemMediumInfo: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    mediumInfo: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    itemSmallInfo: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    smallInfo: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    itemImageMax: '6.667rem',
    itemImageMin: '4.167rem',
    infoMin: '10rem',
    priceMin: '4.167rem',
    itemScrollHeight: '33.333rem',
    iconSize: '2rem',
    itemImagePreview: '4.167rem',
  },
  banner: {
    padding: '0.668rem',
    paddingDesktop: '1.336rem',
    badgesmall: '4.167rem',
    badgebig: '5.833rem',
    paddingTablet: '1.336rem',
    paddingMobile: '1.336rem',
    textSpacingDesktopLarge: '1.336rem',
    textSpacingMobileLarge: '1.336rem',
    headlineDefaultDesktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    headlineDefaultTablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    headlineDefaultMobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    bodyDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    bodyTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bodyMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    textSpacingTabletLarge: '1.336rem',
    desktopStandardHeight: '25rem',
    desktopShort: '17.5rem',
    tabletTall: '24.167rem',
    tabletStandard: '20.333rem',
    tabletShort: '11.333rem',
    headlineSmallDesktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    headlineSmallTablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    headlineSmallMobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    verticalImageHeight: '29.167rem',
    superheadlineDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    superheadlineTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    superheadlineMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    textSpacingDesktopSmall: '0.334rem',
    textSpacingTabletSmall: '0.334rem',
    textSpacingMobileSmall: '0.334rem',
    spotlightBorderRadius: '8px',
    stampMobileTablet: '8.333rem',
    stampDesktop: '9.583rem',
    stampPaddingSmall: '2.004rem',
    stampPaddingDefault: '3.34rem',
    smallVerticalImageHeight: '20.833rem',
    spotlightContentMinHeight: '15rem',
    logoHeight: '2.667rem',
    maxWidth: '114.667rem',
    fullBleedMaxWidth: '416.667rem',
    spotlightLogoSize: '8.333rem',
    mediaSpotlightImageDesktopMax: '33.333rem',
    mediaSpotlightScrollerDesktopMax: '56.25rem',
    mediaSpotlightImageDesktopMin: '20.833rem',
    mediaSpotlightImageDesktopSmallMax: '31.667rem',
    mediaSpotlightScrollerDesktopSmallMax: '44.333rem',
    mediaSpotlightImageTabletlMax: '20.917rem',
    mediaSpotlightScrollerTabletlMax: '44.75rem',
    mediaSpotlightImageMobileMax: '50rem',
    mediaSpotlightScrollerMobileMax: '50rem',
    stampTextSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    stampTextLarge: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    stampTextExtraLarge: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    desktopContainerMaxWidth: '112rem',
    tabletContainerMaxWidth: '74.667rem',
  },
  link: {
    iconSize: '1.333rem',
    extraSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
  },
  star: {
    large: '1.667rem',
    small: '1.333rem',
    spaceBetween: '0rem',
  },
  bold: '600',
  action: {
    primaryDefault: '#2F2F2F',
    primaryHover: '#757575',
    primaryFocus: '#2F2F2F',
    primaryPressed: '#757575',
    primaryLoading: '#2F2F2F',
    primaryDisabled: '#EAEAEA',
    secondaryDefault: '#ffffff',
    secondaryHover: '#F5F5F5',
    secondaryFocus: '#ffffff',
    secondaryPressed: '#F5F5F5',
    secondaryLoading: '#ffffff',
    secondaryDisabled: '#EAEAEA',
    tertiaryDefault: 'transparent',
    tertiaryHover: '#F5F5F5',
    tertiaryFocus: 'transparent',
    tertiaryPressed: '#F5F5F5',
    tertiaryLoading: 'transparent',
    tertiaryDisabled: 'transparent',
  },
  elevation: {
    low: '0px 4px 8px 0px rgba(64, 56, 64, 0.12)',
    lowUpward: '0px -4px 8px 0px rgba(64,56,64, 0.12)',
    high: '0px 12px 22px 0px rgba(117,117,117, 0.25)',
    highUpward: '0px -12px 22px 0px rgba(117,117,117, 0.25)',
  },
  ordersummary: {
    elevation: '0px 4px 14px 0px rgba(0, 0, 0, 0.08)',
    borderStyle: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    default: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    small: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    widthDesktop: '25rem',
    lineItemWidth: '7.083rem',
    tooltipWidth: '16.667rem',
    borderRadius: '8px',
    padding: '2.004rem',
  },
  modals: {
    title: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    itemHeaderBold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    closeIcon: '1.667rem',
    closeFrame: '4rem',
    largeContentSpacing: '2.004rem',
    mediumContentSpacing: '1.336rem',
    smallContentSpacing: '0.668rem',
    defaultPadding: '2.004rem',
    largePadding: '3.34rem',
    smallPadding: '0.668rem',
    itemHeaderRegular: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    titleSmall: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    detailsBody: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    borderRadius: '4px',
    borderRadiusSheet: '12px',
    scrollHeight: '20.833rem',
    small: '33.333rem',
    medium: '50rem',
    large: '66.667rem',
    extraLarge: '83.333rem',
    minHeight: '20.833rem',
    maxWidth: '116.667rem',
    maxHeight: '48.333rem',
    minWidth: '25rem',
    contentMaxHeight: '16.667rem',
    offerReward: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    offerRewardWas: {
      fontFamily: 'GT Eesti Display',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '0',
      textDecoration: 'line-through',
      fontWeight: '600',
    },
    offerImgTitle: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    offerQualification: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    offerQualificationBold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    offerSubcopy: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    offerImageMaxHeight: '18.333rem',
  },
  tile: {
    headlineDesktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    headlineTablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    headlineMobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    bodyDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bodyTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bodyMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    paddingDesktop: '1.002rem',
    paddingTablet: '0.668rem',
    paddingMobile: '0.668rem',
    textSpacing: '0.668rem',
    horizontalHeight: '16.083rem',
    verticalHeight: '17.333rem',
    verticalImageHeight: '14.417rem',
    tallVerticalImageHeight: '28.833rem',
  },
  scrollbar: {
    borderRadius: '12px',
    horizontalHeight: '1.167rem',
    verticalWidth: '1rem',
  },
  skeleton: {
    buttonHeight: '3.333rem',
    large01Desktop: '5.167rem',
    large02Desktop: '4rem',
    large04Desktop: '2.5rem',
    bodyText: '1.667rem',
    smallText: '1.333rem',
    large01Tablet: '4.333rem',
    large02Tablet: '3rem',
    large04Tablet: '2rem',
    large01Mobile: '3.333rem',
    large02Mobile: '2.667rem',
    large04Mobile: '1.833rem',
  },
  alert: {
    title: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    titleSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    subtext: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    subtextSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    details: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    detailsSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    borderRadius: '4px',
    icon: '1.667rem',
    border: '1px',
    padding: '1.336rem',
  },
  fontFamilies: {
    primary: 'GT Eesti Display',
    secondary: 'GT Eesti Text',
  },
  accordion: {
    titleText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    bodyText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    defaultPadding: '0rem',
    largePadding: '1.336rem',
    innerSpacing: '1.336rem',
    extraLargePadding: '2.672rem',
    extraSmallPadding: '0.167rem',
    mobilePadding: '4.667rem',
    tabletPadding: '6rem',
  },
  borderStyle: {
    default: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    none: {
      color: 'transparent',
      width: '1px',
      style: 'solid',
    },
    card: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    emphasized: {
      color: '#2F2F2F',
      width: '1px',
      style: 'solid',
    },
  },
  card: {
    border: '1px',
    horizontalBorderRadius: '8px',
    infoBorderRadius: '8px',
    horizontalImageRadius: '4px',
    headlineDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    headlineTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    headlineMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    bodyDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    bodyTablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    bodyMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    contentSpacing: '0.668rem',
    contentPadding: '1.67rem',
    verticalBorderRadius: '8px',
    horizontalImage: '10rem',
    horizontalImageShort: '6.667rem',
    collectionSpacing: '1.67rem',
    miniInfoImage: '4.167rem',
    infoHeadline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    infoBody: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    infoDisclaimer: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
    infoIcon: '1.333rem',
    infoMinWidth: '23.333rem',
    infoMaxWidth: '30.583rem',
    collectionOuterPadding: '0.668rem',
    miniInfoWidth: '14.583rem',
    collectionPadding: '1.002rem',
    infoPadding: '1.336rem',
    vert2UpMobileMinWidth: '20.167rem',
    vert2UpTabletLargeMinWidth: '37.5rem',
    vert2UpDesktopMinWidth: '37.5rem',
    vert2UpTabletMinWidth: '28.333rem',
    vert3UpDesktopMinWidth: '24.333rem',
    vert3UpTabletLargeMinWidth: '29.333rem',
    vert3UpTabletMinWidth: '20.333rem',
    vert3UpMobileMinWidth: '20.333rem',
    vert4UpDesktopMinWidth: '21.333rem',
    vert4UpTabletLargeMinWidth: '21.333rem',
    vert4UpTabletMinWidth: '20.333rem',
    vert4UpMobileMinWidth: '20.333rem',
  },
  fulfillment: {
    body: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    bodyEmphasized: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    image: '3.333rem',
    imageBorderRadius: '4px',
    headline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    iconInfo: '1.333rem',
    icon: '3.333rem',
    iconStatus: '1.333rem',
    disclaimer: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
  },
  pagination: {
    buttonSize: '3rem',
    borderStyle: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    buttonText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  slider: {
    arrowDefault: '4.333rem',
    arrowSmall: '3.167rem',
    indicatorPadding: '0.668rem',
    indicatorSpacing: '0.668rem',
    borderRadius: '20px',
    arrowBorderRadius: '32px',
    indicatorBorder: '1px',
    indicatorDot: '0.833rem',
    chevronIcon: '2.333rem',
    arrowDistance: '16.667rem',
  },
  breadcrumbs: {
    paddingHorizontal: '0.668rem',
  },
  drawer: {
    widthMed: '42.667rem',
    widthSm: '37.333rem',
    widthLg: '56rem',
  },
  menutilescroller: {
    textHeight: '2.667rem',
    borderRadius: '12px',
    padding: '0.668rem',
    spacing: '0.334rem',
    widthDefault: '8.333rem',
  },
  producttileframe: {
    borderStyle: {
      color: '#EFF8FC',
      width: '2px',
      style: 'solid',
    },
    badgeText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    badgeHeight: '2.667rem',
    badgePadding: '0.668rem',
  },
  menu: {
    smallInnerSpacing: '0.334rem',
    mediumInnerSpacing: '0.5rem',
    extrasmallPadding: '0.5rem',
    smallPadding: '0.668rem',
    defaultButton: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    defaultIcon: '2rem',
    emphasizedButton: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    extralargePadding: '2.672rem',
    extrasmallInnerSpacing: '0.167rem',
    smallIcon: '1.667rem',
    mediumButton: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    mediumPadding: '1.336rem',
    smallBorderRadius: '12px',
    defaultBorderRadius: '20px',
    largePadding: '2.004rem',
    largeInnerSpacing: '1.002rem',
    default: '#2F2F2F',
    hover: '#CB333B',
    selected: '#CB333B',
    lightDefault: '#ffffff',
    lightHover: '#ffffff',
    lightSelected: '#ffffff',
    extralargeInnerSpacing: '2.672rem',
    leftsidebarwidth: '19rem',
    maxWidth: '114.667rem',
    simpleHeight: '6.667rem',
    simpleLogoWidth: '11.667rem',
    smallText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
  },
  informationtext: {
    smallInnerSpacing: '0.334rem',
    mediumInnerSpacing: '0.668rem',
    defaultIcon: '1.667rem',
    defaultText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    borderWidth: '1px',
  },
  filter: {
    chipVertical: '0.167rem',
    chipHorizontal: '0.668rem',
    sortPadding: '0.334rem',
    chipBorderRadius: '20px',
    quickFilterBorderRadius: '4px',
    chipText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    chipIcon: '1.333rem',
    quickfilterHeight: '2.667rem',
    sortIcon: '1.667rem',
    sortItemHeight: '2.667rem',
    sortDropdownWidth: '15rem',
    sortDropdownHeight: '2.667rem',
  },
  focus: {
    default: '#5D9ACDb3',
    onLight: '#000000b3',
    onDark: '#ffffffb3',
  },
  focusRingOnLight: '0px 0px 0px 3px #000000b3',
  focusRingOnDark: '0px 0px 0px 3px #ffffffb3',
  focusRingDefault: '0px 0px 0px 3px #5D9ACDb3',
  logo: {
    largeWidth: '16.667rem',
    largeHeight: '2.167rem',
    smallWidth: '9.75rem',
    smallHeight: '1.75rem',
  },
  atl: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    backgroundDefault: '#EAEAEA',
    backgroundHover: '#ffffff',
    backgroundPressed: '#F5F5F5',
    backgroundAdded: '#ffffff',
    backgroundAddedPressed: '#ffffff',
    borderDefault: '#EAEAEA',
    borderHover: '#B3B3B3',
    borderPressed: '#B3B3B3',
    borderAdded: '#2F2F2F',
    borderAddedPressed: '#ffffff',
    onDefault: '#2F2F2F',
    onHover: '#2F2F2F',
    onPressed: '#CB333B',
    onAdded: '#CB333B',
    onAddedPressed: '#2F2F2F',
    secondaryBackgroundDefault: '#ffffff',
    secondaryBackgroundHover: '#ffffff',
    secondaryBackgroundPressed: '#ffffff',
    secondaryBackgroundAdded: '#ffffff',
    secondaryBackgroundAddedPressed: '#ffffff',
    secondaryBorderDefault: '#ffffff',
    secondaryBorderHover: '#2F2F2F',
    secondaryBorderPressed: '#ffffff',
    secondaryBorderAdded: '#ffffff',
    secondaryBorderAddedPressed: '#ffffff',
    secondaryOnDefault: '#2F2F2F',
    secondaryOnHover: '#2F2F2F',
    secondaryOnPressed: '#ffffff',
    secondaryOnAdded: '#2F2F2F',
    secondaryOnAddedPressed: '#2F2F2F',
    buttonWidth: '5.333rem',
    buttonHeight: '2.833rem',
    iconSize: '1.167rem',
    padding: '0.667rem',
    gap: '0.333rem',
    borderRadius: '4px',
    borderWidthAdded: '1px',
    borderWidthSecondary: '2px',
    elevation: '0px 0px 0px 0px #403840',
    added: '#ffffff',
    hover: '#2F2F2F',
  },
  linklist: {
    itemDefault: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    itemSelected: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  primaryWeight: 'undefined',
  iconbutton: {
    smallIconSize: '1.333rem',
    smallSize: '2.667rem',
    smallborderRadius: '16px',
    largeSize: '6.667rem',
    largeSizeMobile: '5rem',
    largeIconSize: '2rem',
    largePadding: '1.002rem',
    smallPadding: '0.668rem',
    largeborderRadius: '40px',
    defaultText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    largeIconSizeMobile: '1.667rem',
  },
  starRating: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    largeText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  tab: {
    height: '2.667rem',
    spaceBetween: '1.67rem',
    lconSize: '2rem',
    textDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    textDesktopBold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    textMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    textMobileBold: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  paragraph: {
    iconSmall: '1.333rem',
    iconMedium: '1.667rem',
  },
  table: {
    mediumPadding: '1.336rem',
    narrowheaderWidth: '14rem',
    smallPadding: '1.002rem',
    emphasizedText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    defaultText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  default: '0.3',
  imagegallery: {
    thumbnailSize: '6.667rem',
    tallThumbnailSize: '8.333rem',
    thumbnailImageSize: '5rem',
    tallThumbnailImageSize: '6.667rem',
    buttonpaddingLarge: '2.004rem',
    borderRadius: '8px',
    headlinePadding: '4.667rem',
    imageMaxHeightLarge: '41.667rem',
    imageZoomMaxHeight: '66.667rem',
    imageMaxHeightSmall: '25rem',
    thumbnailZoomMaxWidthLg: '73.333rem',
    thumbnailZoomMaxWidthMd: '72.667rem',
    thumbnailZoomMaxWidthSm: '30rem',
  },
  productTitle: {
    brandText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
  },
  caption: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  imageGallery: {
    seeMoreText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    thumbnailText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '0.833rem',
      letterSpacing: '1%',
    },
  },
  pcobasepoints: {
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
  },
  search: {
    queryIconSize: '1.667rem',
    queryImageSize: '3.333rem',
    itemPaddingRight: '1.336rem',
    itemPaddingLeft: '0.668rem',
    queryItemTyped: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    queryItemSuggested: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    leftIndicatorSize: '3.333rem',
    itemTextPaddingTop: '1.336rem',
    itemTextPaddingBottom: '1.336rem',
    dropdownWidth: '46.167rem',
    dropdownPaddingLeft: '0rem',
    dropdownPaddingRight: '0rem',
    dropdownPaddingTop: '0rem',
    dropdownPaddingBottom: '0rem',
    dropdownBorderRadius: '8px',
  },
  queryitem: {
    borderWidth: '1px',
  },
  radiocard: {
    title: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    body: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    smallSpacing: '0.334rem',
    largeSpacing: '1.336rem',
    largePadding: '1.336rem',
  },
  storeIdentifier: {
    logoHeightDesktop: '3rem',
    logoHeightMobile: '2rem',
    logoWidthDesktop: '10.667rem',
    logoWidthMobile: '8rem',
    storeName: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
  },
  header: {
    iconLarge: '4rem',
    iconMedium: '3rem',
    iconSmall: '2rem',
    logoHeight: '8.333rem',
    bannerLogoImageHeightMax: '6.667rem',
    bannerImageHeightMax: '4.167rem',
    bannerLogoImageSpacing: '-2rem',
    logoSmallHeight: '3.333rem',
  },
  utility: {
    utility01: {
      fill01: '#ffffff',
      fill02: '#B3B3B3',
      fill03: '#B3B3B3',
      fill04: '#B3B3B3',
    },
    utility02: {
      fill01: '#ffffff',
      fill02: '#B3B3B3',
      fill03: '#B3B3B3',
      fill04: '#B3B3B3',
    },
    utility03: {
      fill01: '#ffffff',
      fill02: '#B3B3B3',
      fill03: '#B3B3B3',
      fill04: '#B3B3B3',
    },
  },
  testLocalFix: '0.083rem',
  offertile: {
    standardWidth: '14.5rem',
    borderRadius: '8px',
    miniHeight: '7.833rem',
    imageAreaHeight: '12.223rem',
    imageActive: '1',
    imageInactive: '0.5',
    productImageSize: '8.333rem',
    heroImageSize: '12.223rem',
    miniMinWidth: '23.333rem',
    miniMaxWidth: '30.583rem',
    rewardText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    rewardTextWas: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      fontSize: '1.333rem',
      lineHeight: '130%',
      letterSpacing: '0',
      textDecoration: 'line-through',
    },
    titleText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    subcopyText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      fontSize: '1rem',
      lineHeight: '130%',
      letterSpacing: '0',
      textDecoration: 'none',
    },
    qualificationText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    qualificationBoldText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    borderWidth: '1px',
    largeWidth: '28.5rem',
    paddingMedium: '0.668rem',
    paddingLarge: '1.336rem',
    miniQualificationText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    miniQualificationBoldText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    miniImageSize: '6.167rem',
    minHeight: '22.333rem',
    miniMinHeight: '7.833rem',
    miniMaxHeight: '9.833rem',
  },
  progressbar: {
    borderStyle: {
      style: 'solid',
      width: '1px',
      color: '#62B5E5',
    },
  },
  actionList: {
    minHeight: '4.833rem',
    minWidth: '23.333rem',
    maxWidth: '50rem',
    containerMaxWidth: '101.333rem',
    dropdownBorderStyle: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    dropdownWidth: '24rem',
    dropdownBorderRadius: '4px',
  },
  inventoryBadge: {
    opacity: '95%',
  },
  multiStep: {
    medium: '2.004rem',
    small: '1.336rem',
    extrasmall: '0.668rem',
    borderRadius: '12px',
  },
  iab: {
    leaderboardMin: '37.583rem',
    leaderboardMax: '100rem',
    bigboxMin: '25rem',
    bigboxMax: '37.5rem',
    doublebigboxMin: '25rem',
    doublebigboxMax: '37.5rem',
    mbannerMin: '25rem',
    mbannerMax: '37.5rem',
  },
  imagegrid: {
    sixUpDesktopMax: '14.417rem',
    sixUpDesktopMin: '10.5rem',
    fourUpDesktopMin: '16.167rem',
    fourUpDesktopMax: '22.083rem',
    threeUpDesktopMax: '30rem',
    threeUpDesktopMin: '22.083rem',
    twoUpDesktopMax: '45.333rem',
    twoUpDesktopMin: '33.5rem',
    sixUpMobileMax: '15.333rem',
    sixUpMobileMin: '9.667rem',
    fourUpMobileMin: '15.25rem',
    fourUpMobileMax: '23.75rem',
    threeUpMobileMax: '23.75rem',
    threeUpMobileMin: '15.25rem',
    twoUpMobileMax: '33.417rem',
    twoUpMobileMin: '23.083rem',
  },
  swapAndSave: {
    iconSize: '1.667rem',
    headerIconSize: '2rem',
    imageSizeDesktop: '5rem',
    imageSizeMobile: '3.333rem',
    itemPadding: '1.336rem',
    suggestionBorderRadius: '8px',
    imageBorderRadius: '2px',
    subcopy: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    savingsHeadline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    productName: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    price: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    currentHeadline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
  },
  navchip: {
    iconSize: '1.333rem',
    paddingVertical: '0.667rem',
    paddingHorizontal: '1.333rem',
    borderRadius: '40px',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    maxWidth: '16.667rem',
  },
  box: {
    borderRadiusXs: '4px',
    borderRadiusSm: '8px',
    borderRadiusMd: '12px',
    borderRadiusLg: '20px',
    borderRadiusXl: '32px',
    paddingXs: '0.167rem',
    paddingSm: '0.334rem',
    paddingMd: '0.668rem',
    paddingLg: '1.336rem',
    paddingXl: '2.004rem',
    paddingNull: '0rem',
  },
  gridSpacing: {
    default: '0.667rem',
  },
  pointsWidget: {
    ptsValueDesktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '3.666rem',
      letterSpacing: '1%',
    },
    ptsValueMobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.333rem',
      letterSpacing: '1%',
    },
    ptsDesktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    ptsMobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    redeemableValue: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    shadow: '0px 0px 0px 0px #000000',
    insightText: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    imgHeight: '4.167rem',
    boxLg: '2.004rem',
    boxMd: '1.336rem',
    boxSm: '0.668rem',
    gap: '0.668rem',
    borderRadius: '20px',
    gapSm: '0.334rem',
    gapContentLg: '3.34rem',
    gapContentSm: '1.336rem',
    maxWidthDesktop: '66rem',
    borderStyle: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
    headerImgHeightDesktop: '25.333rem',
    headerImgHeightTablet: '20.667rem',
    headerImgHeightMobile: '29rem',
  },
  markdown: {
    heading1Desktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '3.666rem',
      letterSpacing: '1%',
    },
    heading2Desktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.666rem',
      letterSpacing: '1%',
    },
    heading3Desktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    heading4Desktop: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    heading5Desktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading6Desktop: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    heading1Tablet: 'tablet.Tablet Large 01 Bold}',
    heading2Tablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    heading3Tablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    heading4Tablet: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading5Tablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading6Tablet: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    heading1Mobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '2.333rem',
      letterSpacing: '1%',
    },
    heading2Mobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.999rem',
      letterSpacing: '1%',
    },
    heading3Mobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.666rem',
      letterSpacing: '1%',
    },
    heading4Mobile: {
      fontFamily: 'GT Eesti Display',
      fontWeight: '600',
      lineHeight: '120%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading5Mobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    heading6Mobile: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    paragraph: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    paragraphSmall: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '150%',
      fontSize: '1.166rem',
      letterSpacing: '1%',
    },
    listItem: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
  },
  loadIndicator: {
    iconSize: '2rem',
    text: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.333rem',
      letterSpacing: '1%',
    },
    borderRadius: '32px',
    border: {
      color: 'transparent',
      width: '0',
      style: 'solid',
    },
  },
  productItemImage: {
    boxPadding: '0rem',
    opacity: '0.25',
  },
  drawerFooter: {
    elevation: '0px 0px 0px 0px #000',
  },
  freeItems: {
    boxPadding: '0rem',
    borderRadius: '0px',
    borderWidth: '0px',
    imageSize80: '6.667rem',
    imageSize50: '4.167rem',
  },
  freeItemsThumbnail: {
    opacity: '100%',
  },
  productTile: {
    borderStyle: {
      color: '#CACACA',
      width: '1px',
      style: 'solid',
    },
  },
  orderPref: {
    fulfHeadline: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: '1.5rem',
      letterSpacing: '1%',
    },
    fulfDetail: {
      fontFamily: 'GT Eesti Text',
      fontWeight: '300',
      lineHeight: '130%',
      fontSize: '1rem',
      letterSpacing: '1%',
    },
    borderRadius: '8px',
    borderWidth: '1px',
  },
  popover: {
    borderRadius: '12px',
    widthSmall: '29.167rem',
    widthMedium: '37.5rem',
    widthLarge: '45.833rem',
    widthExtraSmall: '20.833rem',
  },
  mastheadMenu: {
    gap: '1.67rem',
    mastheadMenugap: '1.67rem',
  },
  accordionBodyText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  accordionTitleText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  accordionIcon: '2rem',
  accordionDefaultPadding: '0rem',
  accordionLargePadding: '1.336rem',
  accordionInnerSpacing: '1.336rem',
  accordionSmallIcon: '1.667rem',
  accordionExtraLargePadding: '2.672rem',
  accordionExtraSmallPadding: '0.167rem',
  accordionMobilePadding: '4.667rem',
  accordionTabletPadding: '6rem',
  actionListMinHeight: 'actionList.minHeight',
  actionListMinWidth: '23.333rem',
  actionListMaxWidth: '50rem',
  actionListContainerMaxWidth: '101.333rem',
  actionListDropdownWidth: '24rem',
  actionListDropdownBorderStyle: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  actionListdropdownBorderRadius: '4px',
  alertsBorderRadius: '4px',
  alertsIconSize: '1.667rem',
  elevationShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
  alertsInteriorSpacing: '0.668rem',
  alertsBorderWidth: '0.667rem',
  alertsTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  alertTitleSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  alertsSubtext: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  alertSubtextSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  alertsUnderline: 'underline',
  alertsDetails: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  alertDetailsSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  alertBorder: '1px',
  alertPadding: '1.336rem',
  atcIconSize: '2rem',
  atcPaddingVertical: '0.668rem',
  atcPaddingHorizontal: '0.668rem',
  atcBorderRadius: '20px',
  atcQuantityText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  atcBorderRadiusFull: '32px',
  atcButtonMaxWidth: '20rem',
  atcIncrementBorderRadius: '12px',
  atcDisabledIconOpacity: '0.4',
  atcInputWidth: '4.167rem',
  atcInputWidthCart: '3.333rem',
  atcCompactSize: '3.333rem',
  atlIconSize: '1.167rem',
  atlIconSizeFull: '2rem',
  atlButtonWidth: '5.333rem',
  atlPadding: '0.667rem',
  atlPaddingFullHorizontal: '0.668rem',
  atlPaddingFullVertical: '0.668rem',
  atlBorderRadius: '4px',
  atlBorderRadiusFull: '32px',
  atlBorderWidthAdded: '1px',
  atlElevation: '0px 0px 0px 0px #000',
  atlText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  atlTextFull: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  atlGap: '0.333rem',
  atlButtonHeight: '2.833rem',
  atlButtonHeightFull: '3.333rem',
  badgePaddingVertical: '0.167rem',
  badgeBorderRadius: '20px',
  badgeText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  badgePaddingHorizontal: '0.668rem',
  badgeIconSize: '1.333rem',
  badgeElevation: '0px 0px 0px 0px #000',
  dealbadgeIconSize: '1.333rem',
  dealBadgeText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  badgeStockPadding: '0.167rem',
  badgeStockBorderRadius: '4px',
  dealBadgeTextLarge: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  badgeHeight: '1.5rem',
  productMessageIconSize: '1.333rem',
  inventoryBadgeOpacity: '95%',
  bannerDefaultHeadlineDesktop: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '2.666rem',
    letterSpacing: '1%',
  },
  bannerContentPaddingDesktop: '1.336rem',
  bannerContentSpacingDesktop: '2.004rem',
  bannerTextSpacingDesktopLarge: '1.336rem',
  bannerBadgeBig: '5.833rem',
  bannerBadgeSmall: '4.167rem',
  bannerLogoHeight: '2.667rem',
  bannerDefaultHeadlineTablet: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  bannerDefaultHeadlineMobile: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  bannerBodyDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  bannerBodyTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  bannerContentPaddingTablet: '1.336rem',
  bannerTextSpacingTabletLarge: '1.336rem',
  bannerContentSpacingTablet: '1.336rem',
  bannerBodyMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  bannerContentPaddingMobile: '1.336rem',
  bannerContentSpacingMobile: '1.336rem',
  bannerTextSpacingMobileLarge: '1.336rem',
  bannerSmallHeadlineDesktop: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  bannerSmallHeadlineTablet: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  bannerSmallHeadlineMobile: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  bannerVerticalImageHeight: '29.167rem',
  bannerSmallVerticalImageHeight: '20.833rem',
  bannerSuperheadlineDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  bannerSuperheadlineTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  bannerSuperheadlineMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  bannerTextSpacingDesktopSmall: '0.334rem',
  bannerTextSpacingTabletSmall: '0.334rem',
  bannerTextSpacingMobileSmall: '0.334rem',
  bannerSpotlightBorderRadius: '8px',
  bannerStampMobileTablet: '8.333rem',
  bannerStampDesktop: '9.583rem',
  bannerStampPaddingSmall: '2.004rem',
  bannerStampPaddingDefault: '3.34rem',
  bannerStampTextSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  bannerStampTextLarge: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  bannerStampTextExtraLarge: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  bannerSpotlightContentMinHeight: '15rem',
  bannerMaxWidth: '114.667rem',
  bannerFullBleedMaxWidth: '416.667rem',
  spotlightLogoImageSize: '8.333rem',
  mediaSpotlightImageDesktopMax: '33.333rem',
  mediaSpotlightScrollerDesktopMax: '56.25rem',
  mediaSpotlightImageDesktopSmallMax: '31.667rem',
  mediaSpotlightScrollerDesktopSmallMax: '44.333rem',
  mediaSpotlightImageTabletMax: '20.917rem',
  mediaSpotlightScrollerTabletMax: '44.75rem',
  mediaSpotlightImageMobileMax: '50rem',
  mediaSpotlightScrollerMobileMax: '50rem',
  mediaSpotlightImageDesktopMin: '20.833rem',
  bannerDesktopContainerMaxWidth: '112rem',
  bannerTabletContainerMaxWidth: '74.667rem',
  tableMediumPadding: '1.336rem',
  tableSmallPadding: '1.002rem',
  tableNarrowHeaderWidth: '14rem',
  tableEmphasizedText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  tableDefaultText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  blocksItemSmallInfo: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  blocksSmallInfo: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  blocksItemMediumInfo: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksMediumInfo: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksItemTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksInfoDefault: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  blocksItemImageMin: '4.167rem',
  blocksItemImageMax: '6.667rem',
  blocksItemImagePreview: '4.167rem',
  blocksInfoMin: '10rem',
  blocksPriceMin: '4.167rem',
  blocksItemScrollHeight: '33.333rem',
  blocksIconSize: '2rem',
  breadcrumbsPaddingHorizontal: '0.668rem',
  browseSidebarAccordionTitleText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  browseSidebarListHeight: '26.333rem',
  linkListItemDefault: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  linkListItemSelected: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  linkListItemHover: 'underline',
  browseSidebarWidth: '25rem',
  browseSidebarSectionHeadingLarge: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  browseSidebarWidthSm: '17.5rem',
  activeFilterIcon: '0.667rem',
  browseSidebarSectionHeadingSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  buttonSmallVertical: '0.334rem',
  buttonSmallHorizontal: '0.334rem',
  buttonSmallIcon: '1.167rem',
  buttonBorderRadius: '32px',
  buttonSmallInterior: '0.334rem',
  buttonMediumIcon: '1.333rem',
  buttonMediumHorizontal: '0.668rem',
  buttonMediumVertical: '0.334rem',
  buttonMediumInterior: '0.334rem',
  buttonLargeIcon: '2rem',
  buttonLargeVertical: '0.668rem',
  buttonLargeHorizontal: '0.668rem',
  buttonLargeInterior: '0.334rem',
  buttonBorderWidth: '1px',
  buttonSmallText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  buttonMediumText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  buttonLargeText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  buttonSmallHeight: '2rem',
  buttonMediumHeight: '2.667rem',
  buttonLargeHeight: '3.333rem',
  buttonFaveBorderRadius: '32px',
  buttonUtilityBorderRadius: '8px',
  buttonUtilityBorderRadiusSm: '4px',
  buttonUtilityInterior: '0.668rem',
  buttonUtilityInteriorSm: '0.334rem',
  buttonUtilityText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  buttonUtilityTextSm: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  buttonMinWidth: '2rem',
  buttonMenuHeight: '2.667rem',
  menuBorderRadius: '20px',
  buttonMenuInterior: '0.668rem',
  menuButtonText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  cardHeadlineDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  cardHeadlineTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  cardHeadlineMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  cardBodyDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  cardBodyTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  cardBodyMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  cardContentPadding: '1.67rem',
  cardContentSpacing: '0.668rem',
  cardHorizontalImage: '10rem',
  cardHorizontalImageShort: '6.667rem',
  cardHorizontalBorderRadius: '8px',
  cardBorder: '1px',
  cardHorizontalImageRadius: '4px',
  cardVerticalBorderRadius: '8px',
  cardBorderStyle: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  cardCollectionSpacing: '1.67rem',
  cardMiniInfoImage: '4.167rem',
  cardInfoHeadline: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  cardInfoBody: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  cardInfoDisclaimer: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  cardInfoMinWidth: '23.333rem',
  cardInfoMaxWidth: '30.583rem',
  cardInfoIcon: '1.333rem',
  cardInfoPadding: '1.336rem',
  cardMiniInfoWidth: '14.583rem',
  cardInfoBorderRadius: '8px',
  cardCollectionPadding: '1.002rem',
  cardCollectionOuterPadding: '0.668rem',
  cardVert2UpDesktopMinWidth: '37.5rem',
  cardVert2UpTabletLargeMinWidth: '37.5rem',
  cardVert2UpTabletMinWidth: '28.333rem',
  cardVert2UpMobileMinWidth: '20.167rem',
  cardVert3UpDesktopMinWidth: '24.333rem',
  cardVert3UpTabletLargeMinWidth: '29.333rem',
  cardVert3UpTabletMinWidth: '20.333rem',
  cardVert3UpMobileMinWidth: '20.333rem',
  cardVert4UpDesktopMinWidth: '21.333rem',
  cardVert4UpTabletLargeMinWidth: '21.333rem',
  cardVert4UpTabletMinWidth: '20.333rem',
  cardVert4UpMobileMinWidth: '20.333rem',
  controlIconMedium: '1.667rem',
  controlIconLarge: '2rem',
  checkboxBorderRadius: '2px',
  controlMediumLabel: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  controlLargeLabel: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  controlBorderWidth: '1px',
  switchBorderRadius: '20px',
  controlMediumLabelPadding: '0.334rem',
  controlLargeLabelPadding: '0.167rem',
  drawerWidthMed: '42.667rem',
  drawerWidthSm: '37.333rem',
  drawerWidthLg: '56rem',
  drawerFooterElevation: '0px 0px 0px 0px #000',
  filterChipIconSize: '1.333rem',
  filterChipVertical: '0.167rem',
  filterChipHorizontal: '0.668rem',
  filterChipBorderRadius: '20px',
  filterChipText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  filterQuickfilterHeight: '2.667rem',
  filterSortIconSize: '1.667rem',
  filterSortPadding: '0.334rem',
  filterSortItemHeight: '2.667rem',
  filterQuickFilterBorderRadius: '4px',
  quickFilterBorderWidth: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  sortDropdownWidth: '15rem',
  sortDropdownHeight: '2.667rem',
  navChipIconSize: '1.333rem',
  navChipPaddingVertical: '0.667rem',
  navChipPaddingHorizontal: '1.333rem',
  navChipBorderRadius: '40px',
  navChipText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  navChipMaxWidth: '16.667rem',
  formMediumLabelText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  formMediumIcon: '1.667rem',
  formLargeIcon: '2rem',
  tooltipSize: '1.333rem',
  formHelperText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  formMediumInputText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  formMediumVertical: '0.334rem',
  formMediumHorizontal: '0.668rem',
  formInterior: '0.668rem',
  formLargeInputText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  formLargeVertical: '0.668rem',
  formBorderRadius: '4px',
  formSearchBorderRadius: '20px',
  formLargeHorizontal: '1.002rem',
  formLargeLabelText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  formSearchMediumHorizontal: '1.336rem',
  formSearchLargeHeight: '3.333rem',
  formTextAreaHeight: '7.5rem',
  formMiniFormButtonAlignment: '1.833rem',
  formTextMinWidth: '20.833rem',
  fulfillmentImage: '3.333rem',
  fulfillmentImageBorderRadius: '4px',
  fulfillmentHeadline: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  fulfillmentIconInfo: '1.333rem',
  fulfillmentBody: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  fulfillmentBodyEmphasized: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  fulfillmentIcon: '3.333rem',
  fulfillmentIconStatus: '1.333rem',
  fulfillmentDisclaimer: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  iconButtonSmallIconSize: '1.333rem',
  iconButtonLargeIconSizeMobile: '2rem',
  iconButtonSmallBorderRadius: '16px',
  iconButtonSmallSize: '2.667rem',
  iconButtonLargeSize: '6.667rem',
  iconButtonLargeSizeMobile: '5rem',
  iconButtonLargeIconSize: '2rem',
  iconButtonLargeSpacing: '1.002rem',
  iconButtonSmallPadding: '0.668rem',
  iconButtonLargeBorderRadius: '40px',
  iconButtonDefaultText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  imageGalleryThumbnailSize: '6.667rem',
  imageGalleryTallThumbnailSize: '8.333rem',
  imageGalleryThumbnailImageSize: '5rem',
  imageGalleryTallThumbnailImageSize: '6.667rem',
  imageGalleryButtonPaddingLarge: '2.004rem',
  imageGalleryBorderRadius: '8px',
  imageGalleryHeadlinePadding: '4.667rem',
  imageGalleryCaptionText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  imageGallerySeeMoreText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  imageGalleryControlNavDotLarge: '0.833rem',
  imageGalleryControlNavDotMed: '0.667rem',
  imageGalleryControlNavDotSmall: '0.5rem',
  imageGalleryControlNavDotLast: '0.333rem',
  imageGalleryImageMaxHeightLarge: '41.667rem',
  imageGalleryImageMaxHeightSmall: '25rem',
  imageGalleryImageZoomMaxHeight: '66.667rem',
  imageGalleryThumbnailText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  imageGalleryThumbnailZoomMaxWidthLg: '73.333rem',
  imageGalleryThumbnailZoomMaxWidthMd: '72.667rem',
  imageGalleryThumbnailZoomMaxWidthSm: '30rem',
  imageGridSixUpDesktopMax: '14.417rem',
  imageGridSixUpDesktopMin: '10.5rem',
  imageGridFourUpDesktopMax: '22.083rem',
  imageGridFourUpDesktopMin: '16.167rem',
  imageGridThreeUpDesktopMin: '22.083rem',
  imageGridThreeUpDesktopMax: '30rem',
  imageGridTwoUpDesktopMax: '45.333rem',
  imageGridTwoUpDesktopMin: '33.5rem',
  imageGridSixUpMobileMax: '15.333rem',
  imageGridSixUpMobileMin: '9.667rem',
  imageGridFourUpMobileMax: '23.75rem',
  imageGridFourUpMobileMin: '15.25rem',
  imageGridThreeUpMobileMax: '23.75rem',
  imageGridThreeUpMobileMin: '15.25rem',
  imageGridTwoUpMobileMax: '33.417rem',
  imageGridTwoUpMobileMin: '23.083rem',
  informationtextSmallInnerSpacing: '0.334rem',
  informationtextMediumInnerSpacing: '0.668rem',
  informationtextDefaultIcon: '1.667rem',
  informationtextDefaultText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  informationtextBorderWidth: '1px',
  linkIconSize: '1.333rem',
  linkDefault: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  linkUnderline: 'underline',
  linkSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  linkExtraSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  menuSmallInnerSpacing: '0.334rem',
  menuMediumInnerSpacing: '0.5rem',
  menuExtraSmallPadding: '0.5rem',
  menuSmallPadding: '0.668rem',
  menuDefaultButton: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  menuDefaultIcon: '2rem',
  menuEmphasizedButton: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  menuExtraLargePadding: '2.672rem',
  menuExtraSmallInnerSpacing: '0.167rem',
  menuSmallIcon: '1.667rem',
  menuMediumButton: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  menuMediumPadding: '1.336rem',
  menuSmallBorderRadius: '12px',
  menuDefaultBorderRadius: '20px',
  menuLargePadding: '2.004rem',
  menuLargeInnerSpacing: '1.002rem',
  menubuttonUnderline: 'underline',
  menuExtraLargeInnerSpacing: '2.672rem',
  menuLeftSidebarWidth: '19rem',
  menuMaxWidth: '114.667rem',
  menuSimpleLogoWidth: '11.667rem',
  menuSimpleHeight: '6.667rem',
  menuSmallText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  menuTileScrollerTextHeight: '2.667rem',
  menuTileScrollerPadding: '0.668rem',
  menuTileScrollerBorderRadius: '12px',
  menuTileScrollerSpacing: '0.334rem',
  menuTileScrollerWidthDefault: '8.333rem',
  miniProductTileHeight: '7.667rem',
  miniProductTileImageSize: '5rem',
  miniProductTileBorderRadius: '8px',
  miniProductTileBorder: '1px',
  miniProductTileActiveOpacity: '0.5',
  miniProductTileMinWidth: '23.333rem',
  miniProductTileMaxWidth: '30.583rem',
  modalsItemHeaderBold: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  modalsItemHeaderRegular: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  modalsDetailsBody: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  modalsItemImage: '6.667rem',
  modalsItemListBold: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  modalsStrikethrough: 'line-through',
  modalsTitle: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  modalsTitleSmall: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  modalsLargeContentSpacing: '2.004rem',
  modalsMediumContentSpacing: '1.336rem',
  modalsSmallContentSpacing: '0.668rem',
  modalsDefaultPadding: '2.004rem',
  modalsLargePadding: '3.34rem',
  modalsSmallPadding: '0.668rem',
  modalsCloseIcon: '1.667rem',
  modalsCloseFrame: '4rem',
  modalsBorderRadius: '4px',
  modalsBorderRadiusSheet: '12px',
  modalsScrollHeight: '20.833rem',
  modalsSmall: '33.333rem',
  modalsMedium: '50rem',
  modalsLarge: '66.667rem',
  modalsExtraLarge: '83.333rem',
  modalsMinHeight: '20.833rem',
  modalsMaxWidth: '116.667rem',
  modalsMaxHeight: '48.333rem',
  modalsMinWidth: '25rem',
  modalsContentMaxHeight: '16.667rem',
  modalsOfferReward: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  modalsOfferRewardWas: {
    fontFamily: 'GT Eesti Display',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '0',
    textDecoration: 'line-through',
    fontWeight: '600',
  },
  modalsOfferImgTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  modalsOfferQualification: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  modalsOfferQualificationBold: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  modalsOfferSubcopy: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  modalsOfferImageMaxHeight: '18.333rem',
  offerTileHeaderLogoSize: '2.167rem',
  offerTileHeaderPaddingVertical: '0.167rem',
  offerTileHeaderGap: '0.167rem',
  offerTileHeaderText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  offerTileHeaderIconSize: '1.333rem',
  offerTileStandardWidth: '14.5rem',
  offerTileBorderRadius: '8px',
  offerTileMiniHeight: '7.833rem',
  offerTileImageAreaHeight: '12.223rem',
  offerTileImageActive: '1',
  offerTileImageInactive: '0.5',
  offerTileProductImageSize: '8.333rem',
  offerTileHeroImageSize: '12.223rem',
  offerTileMiniMinWidth: '23.333rem',
  offerTileMiniMaxWidth: '30.583rem',
  offerTileRewardText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  offerTileRewardTextWas: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    fontSize: '1.333rem',
    lineHeight: '130%',
    letterSpacing: '0',
    textDecoration: 'line-through',
  },
  offerTileTitleText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  offerTileSubcopyText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    fontSize: '1rem',
    lineHeight: '130%',
    letterSpacing: '0',
    textDecoration: 'none',
  },
  offerTileQualificationText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  offerTileQualificationBoldText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  offerTileBorderWidth: '1px',
  offerTileLargeWidth: '28.5rem',
  offerTilePaddingLarge: '1.336rem',
  offerTilePaddingMedium: '0.668rem',
  offerTileMiniQualificationText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  offerTileMiniQualificationBoldText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  offerTileMiniImageSize: '6.167rem',
  offerTileMinHeight: '22.333rem',
  offerTileMiniMinHeight: '7.833rem',
  offerTileMiniMaxHeight: '9.833rem',
  orderPrefFulfHeadline: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  orderPrefFulfDetail: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  orderPrefBorderRadius: '8px',
  orderPrefBorderWidth: '1px',
  ordersummaryDefault: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  ordersummaryBold: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  ordersummarySmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  ordersummaryWidthDesktop: '25rem',
  ordersummaryLineItemWidth: '7.083rem',
  ordersummaryTooltipWidth: '16.667rem',
  ordersummaryBorderStyle: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  ordersummaryBorderRadius: '8px',
  ordersummaryPadding: '2.004rem',
  ordersummaryElevation: '0px 0px 0px 0px #000',
  paginationButtonSize: '3rem',
  paginationButtonBorderRadius: '32px',
  paginationButtonText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  paginationBorderStyle: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  paginationIconSize: '1.333rem',
  pcoBasePointsText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  popoverWidthSmall: '29.167rem',
  popoverWidthExtraSmall: '20.833rem',
  popoverWidthMedium: '37.5rem',
  popoverWidthLarge: '45.833rem',
  popoverBorderRadius: '12px',
  priceText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  priceTextSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  priceWasTextSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  priceBigDeal: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  priceTextSecondary: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  priceWasText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  priceWasTextSecondary: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  priceTextPrimary: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  priceWasTextPrimary: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.5rem',
    letterSpacing: '1%',
  },
  priceDescriptor: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  priceInfoTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  priceInfoSubtitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  priceInfoDetails: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  priceInfoBorderWidth: '3px',
  priceUnitText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  priceTextSmallDeem: 'price.textSmallDeem',
  priceTextXSmall: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  priceTextXSmallDeem: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '0.833rem',
    letterSpacing: '1%',
  },
  productItemImageboxPadding: '0rem',
  productItemImageOpacity: '0.25',
  productTilePadding: '0.668rem',
  productTileTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  productTilePackageSize: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTilePcoCallout: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileVariants: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileInventory: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileVariantIcon: '1.167rem',
  productTileBorderRadius: '4px',
  productTileBrandTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  productTileImageHeight: '9.167rem',
  productTileBadgeHeight: '1.5rem',
  productTileInfoHeight: '5.167rem',
  productTilePackageSizePadding: '0.167rem',
  productTileMemberPrice: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileHorizAtlMinHeight: '11.75rem',
  productTileImageWidth: '9.167rem',
  productTileImageAreaWidth: '12.833rem',
  productTileBorderStyle: {
    color: '#CACACA',
    width: '1px',
    style: 'solid',
  },
  productTileAddOnText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileRecipientBody: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileRecipientTitle: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileGcBorderRadius: '4px',
  productTileTextMinWidth: '8.333rem',
  productTileTextMinWidthLg: '21.167rem',
  productTilePriceMinWidth: '8.333rem',
  productTilePriceMaxWidth: '16.667rem',
  productTileColumnGap: '2.672rem',
  productTileInvBadgeWidth: '4.167rem',
  productTileWidth: '14.169rem',
  productTitleBrandText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  productTileFrameBorderStyle: {
    color: '#EFF8FC',
    width: '2px',
    style: 'solid',
  },
  productTileFrameBadgeText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  productTileFrameBadgeHeight: '2.667rem',
  productTileFrameBadgePadding: '0.668rem',
  radiocardTitle: 'radiocard.title',
  radiocardBody: 'radiocard.body',
  radiocardSmallSpacing: '0.334rem',
  radiocardLargeSpacing: '1.336rem',
  radiocardLargePadding: '1.336rem',
  scrollbarBorderRadius: '12px',
  scrollbarHorizontalHeight: '1.167rem',
  scrollbarVerticalWidth: '1rem',
  queryIconSize: '1.667rem',
  queryImageSize: '3.333rem',
  searchItemPaddingRight: '1.336rem',
  searchItemPaddingLeft: '0.668rem',
  queryItemTyped: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  queryItemSuggested: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '600',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  queryItemBorderWidth: '1px',
  itemTextPaddingTop: '1.336rem',
  leftIndicatorSize: '3.333rem',
  itemTextPaddingBottom: '1.336rem',
  searchDropdownWidth: '46.167rem',
  searchDropdownPaddingLeft: '0rem',
  searchDropdownPaddingRight: '0rem',
  searchDropdownPaddingTop: '0rem',
  searchDropdownPaddingBottom: '0rem',
  searchDropdownBorderWidth: '8px',
  skeletonProductTileImage: '9.167rem',
  skeletonButtonHeight: '3.333rem',
  skeletonLarge01Desktop: '5.167rem',
  skeletonLarge02Desktop: '4rem',
  skeletonLarge04Desktop: '2.5rem',
  skeletonBodyText: '1.667rem',
  skeletonSmallText: '1.333rem',
  skeletonLarge01Tablet: '4.333rem',
  skeletonLarge02Tablet: '3rem',
  skeletonLarge04Tablet: '2rem',
  skeletonLarge01Mobile: '3.333rem',
  skeletonLarge02Mobile: '2.667rem',
  skeletonLarge04Mobile: '1.833rem',
  sliderBorderRadius: '20px',
  sliderIndicatorPadding: '0.668rem',
  sliderIndicatorSpacing: '0.668rem',
  sliderIndicatorBorder: '1px',
  sliderArrowBorderRadius: '32px',
  sliderArrowDefault: '4.333rem',
  sliderArrowSmall: '3.167rem',
  sliderIndicatorDot: '0.833rem',
  sliderChevronIcon: '2.333rem',
  sliderArrowDistance: '16.667rem',
  starLarge: '1.667rem',
  starSmall: '1.333rem',
  starSpaceBetween: '0rem',
  starRatingReviewText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1rem',
    letterSpacing: '1%',
  },
  starRatingLargeReviewText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  tileHeadlineDesktop: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.999rem',
    letterSpacing: '1%',
  },
  desktopTileContentPadding: '1.002rem',
  desktopTileTextSpacing: '0.668rem',
  tileHeadlineTablet: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  tileHeadlineMobile: {
    fontFamily: 'GT Eesti Display',
    fontWeight: '600',
    lineHeight: '120%',
    fontSize: '1.666rem',
    letterSpacing: '1%',
  },
  tileBodyDesktop: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  tileBodyTablet: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '130%',
    fontSize: '1.333rem',
    letterSpacing: '1%',
  },
  tabletTilePadding: '1.336rem',
  tabletTileTextSpacing: '0.668rem',
  tileBodyMobile: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  mobileTileContentPadding: '1.336rem',
  mobileTileTextSpacing: '0.668rem',
  tileHorizontalHeight: '16.083rem',
  tileVerticalContentHeight: '17.333rem',
  tileVerticalImageHeight: '14.417rem',
  tileTallVerticalImageHeight: '28.833rem',
  variantsSelectedRing: '4rem',
  variantsSwatch: '3rem',
  variantsPaddingDefault: '0.668rem',
  variantsPaddingLarge: '1.336rem',
  variantsSpacingSmall: '0.334rem',
  variantsBorderRadiusLarge: '40px',
  variantsBorderRadiusMedium: '10px',
  variantsBorderRadiusSmall: '4px',
  variantsSwatchDefault: '1px',
  variantsDropdownIcon: '2rem',
  variantsChipText: {
    fontFamily: 'GT Eesti Text',
    fontWeight: '300',
    lineHeight: '150%',
    fontSize: '1.166rem',
    letterSpacing: '1%',
  },
  variantsDropdownHeight: '25rem',
};
